import { GET_COUNTRIES, GET_ERRORS } from './types';
import axios from 'axios';
import { API_COUNTRIES } from '../_constants/apiConstants';
import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';

const apiCountriesEndpoint = API_COUNTRIES;

export const getCountries = () => dispatch => {
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(apiBaseUrl + apiCountriesEndpoint)
            .then(res => {   
                dispatch({
                    type: GET_COUNTRIES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}