import axios from 'axios';
import { GET_COUNTRY_BY_COUNTRYCODE, GET_ERRORS } from './types';
import { API_COUNTRIES } from '../_constants/apiConstants';
import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';

const apiCountriesEndpoint = API_COUNTRIES;

axios.defaults.crossDomain = true;

export const getCountryByCountryCode = (countryCode) => dispatch => {
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(apiBaseUrl + apiCountriesEndpoint + '/' + countryCode)
        .then(res => {
            dispatch({
                type: GET_COUNTRY_BY_COUNTRYCODE,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    });
}

//this is to clear the actual delivery data from the deliveries object in redux
export const setPortalCountryData = (data) => dispatch => {
    return dispatch({
        type: GET_COUNTRY_BY_COUNTRYCODE,
        payload: data
    })
}