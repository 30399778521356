import { GET_CONTACT_TYPES, GET_ERRORS } from './types';
import axios from 'axios';
import { API_BASE_URL, API_CONTACT_TYPES } from '../_constants/apiConstants';

const apiBaseUrl = API_BASE_URL;
const apiContactTypesEndpoint = API_CONTACT_TYPES;

export const getContactTypes = () => dispatch => {
    return axios.get(`${apiBaseUrl}${apiContactTypesEndpoint}`)
        .then(res => {
            dispatch({
                type: GET_CONTACT_TYPES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}