const isEmpty = (value) => {
    // Check for undefined or null
    if (value === undefined || value === null) return true;

    // Check for empty string (trimmed)
    if (typeof value === 'string' && value.trim().length === 0) return true;

    // Check for empty array
    if (Array.isArray(value) && value.length === 0) return true;

    // Check for empty object (including plain objects, Maps, and Sets)
    if (typeof value === 'object') {
        if (value instanceof Map || value instanceof Set) {
            return value.size === 0;
        }
        return Object.keys(value).length === 0;
    }

    // Default to not empty
    return false;
};

export default isEmpty;
