/* eslint-disable no-unused-vars */
import {
    GET_JOBS,
    POST_JOBS,
    PUT_JOBS,
    CLEAR_JOBS,
    CLEAR_JOBS_SUCCESS,
    DELETE_JOBS,
    GET_JOBS_BY_ID,
    UPDATE_JOBS,
    NEW_JOBS,
    GET_JOBS_AGGREGATIONS,
    GET_MORE_JOBS,
    GET_SINGLE_JOB_AND_UPSERT,
    CLEAR_JOBS_LOADED,
    SET_LOADING_JOBS,
    PUT_JOBS_WORKFLOW_MOBILE, 
    SYNC_JOB,
    SYNC_DRIVER_JOBS
} from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: null, // this is for knowing when a success message comes back from the backend after an action is performed, e.g., delete success, post success etc.
    loaded: false, // this is for knowing exactly when jobs are loaded so we can turn off loaders,
    loading: false, // this is for generic loading states as used by lists and tables to determine if we are fetching data
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_JOBS:
            return {
                ...state,
                data: {
                    ...action.payload, items: action.payload.items.reduce((obj, item) => { //key by id for O(1) lookup
                        // item.tasks.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder)); //sort by task job order
                        obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                        return obj;
                    }, {})
                },
                search: !isEmpty(action.search) ? action.search : "",
                loaded: true,
                loading: false
            }
        case GET_MORE_JOBS:
            const data = {
                ...action.payload, items: action.payload.items.reduce((obj, item) => { //key by id for O(1) lookup
                    //item.tasks.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder)); //sort by task job order
                    obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                    return obj;
                }, {
                    ...state.data.items
                })
            };
            return {
                ...state,
                data: data,
                search: !isEmpty(action.search) ? action.search : "",
                loaded: true,
                loading: false
            }
        case GET_JOBS_BY_ID:
            return {
                ...state,
                data: action.payload,
                search: action.search,
                success: true,
                loading: false
            }
        case GET_JOBS_AGGREGATIONS:
            return {
                ...state,
                data: {
                    ...state.data,
                    aggregationDictionary: action.payload.aggregationDictionary,
                    totalItems: action.payload.totalItems,
                    updateAggregations: false
                }
            }
        case POST_JOBS:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} job`,
                loading: false
            }
        case PUT_JOBS:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} job`,
                loading: false
            }
        case SYNC_JOB:
            return {
                ...state,
                success: `Successfully synced job`,
                loading: false
            }
        case SYNC_DRIVER_JOBS:
            let syncedJobs = Object.assign({}, state.data.items);
            for (const obj of action.payload) {
                if(syncedJobs[`#${obj.id}`] && syncedJobs[`#${obj.id}`].xmin < obj.xmin){
                    syncedJobs[`#${obj.id}`] = obj;
                }
            }
           
            return {
                ...state,
                data: { ...state.data, items: syncedJobs },
                loading: false
            }
        case DELETE_JOBS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} jobs.`,
                loading: false
            }
        case SET_LOADING_JOBS:
            return {
                ...state,
                loading: action.payload
            }
        case CLEAR_JOBS_LOADED:
            return {
                ...state,
                loaded: false
            }
        case CLEAR_JOBS_SUCCESS:
            return {
                ...state,
                success: null
            }
        case CLEAR_JOBS:
            return {
                ...state,
                data: {},
                success: null,
                loading: false,
                loaded: false
            }
        case UPDATE_JOBS: //this is for updating jobs in redux with data from signalR
            //here action.payload is hash map (i.e., Map()) of key = jobId, value = job object
            let jobs = Object.assign({}, state.data.items);

            let existingJobs = [];
            if (!isEmpty(state.data.existing)) {
                existingJobs = [...state.data.existing];
            }

            for (const [key, value] of action.payload.entries()) {
                if (jobs[key]) {
                    // first check if the new xmin is greater than the existing xmin
                    if (jobs[key].xmin < value.xmin) {
                        //value.tasks.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder)); //sort by task job order
                        jobs[key] = value;
                    } else {
                        // do nothing if the job has already been updated to the latest xmin
                    }
                } else if (isEmpty(state.data.existing) || !state.data.existing.includes(key)) {
                    if (isEmpty(state.data.new) || !state.data.new.includes(key))
                        existingJobs.push(key);
                }
            }

            return {
                ...state,
                data: { ...state.data, items: jobs, existing: existingJobs, updateAggregations: true }
            }

        case NEW_JOBS: //this is for adding new jobs message in redux with data from signalR
            //here action.payload is hash set (i.e., Set()) of key = jobId

            let newJobs = [];
            if (!isEmpty(state.data.new)) {
                newJobs = [...state.data.new];
            }

            for (const value of action.payload.values()) {
                newJobs.push(value);
            }

            return {
                ...state,
                data: { ...state.data, new: newJobs, updateAggregations: true }
            }

        case GET_SINGLE_JOB_AND_UPSERT: // this is for fetching a single job and upserting into redux
            let updatedJobs = Object.assign({}, state.data.items);
            //sort by task job order
            //action.payload.tasks.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder));
            updatedJobs[`#${action.payload.id}`] = action.payload;

            return {
                ...state,
                data: { ...state.data, items: updatedJobs }
            }
        case PUT_JOBS_WORKFLOW_MOBILE:

            // this is the job to update based on our response from the backend (JobWorkflowsController)
            let jobToUpdate = action.payload.jobs[0];

            // check if the new jobs xmin is greater than the existing, if so, we update
            if (state.data.items[`#${jobToUpdate.id}`] && state.data.items[`#${jobToUpdate.id}`].xmin < jobToUpdate.xmin) {
                //sort by task job order
                //jobToUpdate.tasks.sort((a, b) => parseInt(a.taskJobOrder) - parseInt(b.taskJobOrder));

                // these are existing jobs in redux
                let jobsInRedux = { ...state.data.items };

                // go ahead and update the job in question
                jobsInRedux[`#${jobToUpdate.id}`] = jobToUpdate;

                // insert back into redux
                return {
                    ...state,
                    data: { ...state.data, items: jobsInRedux }
                }
            } else {
                // else do nothing
                return state;
            }

        default:
            return state;
    }
}