import { GET_PARCEL_TYPES, GET_ERRORS, SET_LOADING_PARCEL_TYPES } from './types';
import axios from 'axios';
import { API_BASE_URL, API_PARCEL_TYPES } from '../_constants/apiConstants';


const apiBaseUrl = API_BASE_URL;
const apiParcelTypesEndpoint = API_PARCEL_TYPES;

export const getParcelTypes = () => dispatch => {

     // turn loading on
     _setLoading(dispatch, true);

    return axios.get(apiBaseUrl + apiParcelTypesEndpoint)
        .then(res => {

             // loading gets turned off here
            dispatch({
                type: GET_PARCEL_TYPES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

             // turn loading off
             _setLoading(dispatch, false);
        });
}

/** Helper functions **/
const _setLoading = (dispatch, loading = false) => {
    dispatch({
        type: SET_LOADING_PARCEL_TYPES,
        payload: loading
    });
}