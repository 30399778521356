import {
    SIDEBAR_TOGGLE,
    LEFT_SIDEBAR_TOGGLE,
    RIGHT_SIDEBAR_TOGGLE,
    USER_MENU_TOGGLE,
    LEFT_SIDEBAR_RESIZE,
    RIGHT_SIDEBAR_RESIZE,
    DASHBOARD_LEFT_TABS_VALUE,
    DASHBOARD_RIGHT_TABS_VALUE,
    DASHBOARD_FILTER,
    CLEAR_DASHBOARD_FILTERS,
    DELIVERY_DETAILS_TOGGLE,
    DELIVERY_LOADING_ON,
    DELIVERY_LOADING_OFF,
    JOB_DETAILS_TOGGLE,
    JOB_LOADING_ON,
    JOB_LOADING_OFF,
    DRIVER_LOADING_ON,
    DRIVER_LOADING_OFF,
    SET_DELIVERY_WIZARD_PROPS,
    SET_ADMIN_TABLE_META,
    CLEAR_ADMIN_TABLE_META,
    SET_DELIVERY_ID_FILTER,
    CLEAR_DELIVERY_ID_FILTERS,
    SET_JOB_ID_FILTER,
    CLEAR_JOB_ID_FILTERS,
    SET_DRIVER_ID_FILTER,
    CLEAR_DRIVER_ID_FILTERS,
    SET_DELIVERY_SEARCH_TERM,
    CLEAR_DELIVERY_SEARCH_TERM,
    GET_SINGLE_JOB_AND_UPSERT,
    GET_SINGLE_DELIVERY_AND_UPSERT,
    GET_SINGLE_DRIVER_AND_UPSERT,
    GET_ERRORS,
    JOB_TASK_LIST_TOGGLE,
    WHATSAPP_WINDOW_OPEN,
    WHATSAPP_WINDOW_CLOSE,
    JOB_TASK_LIST_MULTIPLE_TOGGLE,
    CLEAR_ALL_TASK_LIST_TOGGLES
} from './types';

import store from '../_utils/store';
import { LOADER_TIMEOUT_MILLIS, API_JOBS, API_DELIVERIES, API_USERS } from '../_constants/apiConstants';
import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';
import axios from 'axios';
import isEmpty from '../_utils/isEmpty';

const apiJobsEndpoint = API_JOBS;
const apiDeliveriesEndpoint = API_DELIVERIES;

let jobLoaderTimeoutArray = [];
let deliveryLoaderTimeoutArray = [];
let driverLoaderTimeoutArray = [];

export const sideBarToggle = (toggle) => dispatch => {
    dispatch({
        type: SIDEBAR_TOGGLE,
        payload: toggle
    });
}

export const leftSideBarToggle = (toggle) => dispatch => {
    dispatch({
        type: LEFT_SIDEBAR_TOGGLE,
        payload: toggle
    });
}

export const rightSideBarToggle = (toggle) => dispatch => {
    dispatch({
        type: RIGHT_SIDEBAR_TOGGLE,
        payload: toggle
    });
}

export const leftSideBarResize = (width) => dispatch => {
    dispatch({
        type: LEFT_SIDEBAR_RESIZE,
        payload: width
    })
}

export const rightSideBarResize = (width) => dispatch => {
    dispatch({
        type: RIGHT_SIDEBAR_RESIZE,
        payload: width
    })
}

export const userMenuToggle = (toggle) => dispatch => {
    dispatch({
        type: USER_MENU_TOGGLE,
        payload: toggle
    });
}

export const dashboardLeftTabsValue = (value) => dispatch => {
    return dispatch({
        type: DASHBOARD_LEFT_TABS_VALUE,
        payload: value
    });
}

export const dashboardRightTabsValue = (value) => dispatch => {
    dispatch({
        type: DASHBOARD_RIGHT_TABS_VALUE,
        payload: value
    });
}

export const dashboardFilter = (filter) => dispatch => {
    dispatch({
        type: DASHBOARD_FILTER,
        payload: filter
    });
}

export const clearDashboardFilters = () => dispatch => {
    dispatch({
        type: CLEAR_DASHBOARD_FILTERS,
        payload: ''
    });
}

// sets meta data (i.e., paging filters, sidebar filters etc. for the currently selected admin table)
export const setAdminTableMeta = (meta, entityType) => dispatch => {
    if (entityType) {
        meta.entityType = entityType;
    }

    dispatch({
        type: SET_ADMIN_TABLE_META,
        payload: meta
    });
}

export const clearAdminTableMeta = () => dispatch => {
    dispatch({
        type: CLEAR_ADMIN_TABLE_META,
        payload: ''
    });
}

export const deliveryDetailsToggle = (open) => dispatch => {
    return dispatch({
        type: DELIVERY_DETAILS_TOGGLE,
        payload: open
    });
}

export const jobDetailsToggle = (open) => dispatch => {
    return dispatch({
        type: JOB_DETAILS_TOGGLE,
        payload: open
    });
}

export const setDeliveryWizardProps = (isOpen = false, isLoading = false, step = 0) => dispatch => {
    return dispatch({
        type: SET_DELIVERY_WIZARD_PROPS,
        payload: {
            open: isOpen,
            loading: isLoading,
            step: step
        }
    });
}


export const deliveryLoadingOn = (deliveryIds) => dispatch => {
    _deliveryLoadingOn(deliveryIds, dispatch);
}

export const deliveryLoadingOff = (deliveryIds) => dispatch => {
    //this function is accessible via signal R middleware so we share it like this:
    _deliveryLoadingOff(deliveryIds, dispatch);
}

export const jobLoadingOn = (jobIds) => dispatch => {
    _jobLoadingOn(jobIds, dispatch);
}

export const jobLoadingOff = (jobIds) => dispatch => {
    //this function is accessible via signal R middleware so we share it like this:
    _jobLoadingOff(jobIds, dispatch);
}

export const setDeliveryIdFilter = (deliveryIds) => dispatch => {
    return dispatch({
        type: SET_DELIVERY_ID_FILTER,
        payload: deliveryIds
    })
}

export const clearDeliveryIdFilters = () => dispatch => {
    return dispatch({
        type: CLEAR_DELIVERY_ID_FILTERS
    })
}

export const setJobIdFilter = (jobIds) => dispatch => {
    dispatch({
        type: SET_JOB_ID_FILTER,
        payload: jobIds
    })
}

export const clearJobIdFilters = () => dispatch => {
    return dispatch({
        type: CLEAR_JOB_ID_FILTERS
    })
}

export const setDriverIdFilter = (driverIds) => dispatch => {
    dispatch({
        type: SET_DRIVER_ID_FILTER,
        payload: driverIds
    })
}

export const clearDriverIdFilters = () => dispatch => {
    dispatch({
        type: CLEAR_DRIVER_ID_FILTERS
    })
}

export const setDeliverySearchTerm = (search) => dispatch => {
    dispatch({
        type: SET_DELIVERY_SEARCH_TERM,
        payload: search
    })
}

export const clearDeliverySearchTerm = () => dispatch => {
    dispatch({
        type: CLEAR_DELIVERY_SEARCH_TERM
    })
}

// task list ID is expected to look like this:
//`${job.id}-${JOB_TASK_TYPES.INCOMPLETE}`
//i.e., "149233-incomplete"
export const jobTaskListToggle = (taskListId) => dispatch => {

    if (!isEmpty(taskListId)) {
        dispatch({
            type: JOB_TASK_LIST_MULTIPLE_TOGGLE,
            payload: taskListId
        });
    }

    return dispatch({
        type: JOB_TASK_LIST_TOGGLE,
        payload: taskListId
    });
}
export const clearAllTaskListToggle = () => dispatch => {
    dispatch({
        type: CLEAR_ALL_TASK_LIST_TOGGLES,
        payload: ''
    });
}

/******* helper functions for job loaders and timeouts *******/
export const _jobLoadingOn = (jobIds, dispatch) => {
    dispatch({
        type: JOB_LOADING_ON,
        payload: jobIds
    });
}

export const _jobLoadingOff = (jobIds, dispatch) => {
    jobIds.forEach(jobId => {
        // destroy timeouts created in jobs loading above
        if (jobLoaderTimeoutArray[jobId]) {
            clearTimeout(jobLoaderTimeoutArray[jobId]);
            jobLoaderTimeoutArray[jobId] = null;
        }
    });

    dispatch({
        type: JOB_LOADING_OFF,
        payload: jobIds
    });
}

export const _createJobLoaderTimeout = (jobId, dispatch) => {
    if (jobId) {
        jobLoaderTimeoutArray[jobId] = setTimeout(() => {
            _getSingleJobAndUpsert(jobId, dispatch)
        }, LOADER_TIMEOUT_MILLIS);
    }
}

const _getSingleJobAndUpsert = (jobId, dispatch) => {

    if (isEmpty(jobId)) {
        return;
    }

    let portalCountryCode = store.getState().portal.data.cca2;

    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${apiJobsEndpoint}/${portalCountryCode}/${jobId}`)
            .then(res => {
                dispatch({
                    type: GET_SINGLE_JOB_AND_UPSERT,
                    payload: res.data
                });
            }).then(() => {
                dispatch({
                    type: JOB_LOADING_OFF,
                    payload: [jobId]
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
                dispatch({
                    type: JOB_LOADING_OFF,
                    payload: [jobId]
                });
            });
    });
}

/******* helper functions for delivery loaders and timeouts *******/
export const _deliveryLoadingOn = (deliveryIds, dispatch) => {
    dispatch({
        type: DELIVERY_LOADING_ON,
        payload: deliveryIds
    });
}

export const _deliveryLoadingOff = (deliveryIds, dispatch) => {
    deliveryIds.forEach(deliveryId => {
        // destroy timeouts created in deliveryies loading above
        if (deliveryLoaderTimeoutArray[deliveryId]) {
            clearTimeout(deliveryLoaderTimeoutArray[deliveryId]);
            deliveryLoaderTimeoutArray[deliveryId] = null;
        }
    });

    dispatch({
        type: DELIVERY_LOADING_OFF,
        payload: deliveryIds
    });
}

export const _deliveryLoaderTimeout = (deliveryIds, dispatch) => {
    deliveryIds.forEach(deliveryId => {
        _createDeliveryLoaderTimeout(deliveryId, dispatch);
    });
}

export const _createDeliveryLoaderTimeout = (deliveryId, dispatch) => {
    if (deliveryId) {
        deliveryLoaderTimeoutArray[deliveryId] = setTimeout(() => {
            _getSingleDeliveryAndUpsert(deliveryId, dispatch)
        }, LOADER_TIMEOUT_MILLIS);
    }
}

const _getSingleDeliveryAndUpsert = (deliveryId, dispatch) => {

    if (isEmpty(deliveryId)) {
        return;
    }

    let portalCountryCode = store.getState().portal.data.cca2;

    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${apiDeliveriesEndpoint}/${portalCountryCode}/${deliveryId}`)
            .then(res => {
                dispatch({
                    type: GET_SINGLE_DELIVERY_AND_UPSERT,
                    payload: res.data
                });
            }).then(() => {
                dispatch({
                    type: DELIVERY_LOADING_OFF,
                    payload: [deliveryId]
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
                dispatch({
                    type: DELIVERY_LOADING_OFF,
                    payload: [deliveryId]
                });
            });
    });
}

/******* helper functions for drivers loaders and timeouts *******/
export const _driverLoadingOn = (driverIds, dispatch) => {
    dispatch({
        type: DRIVER_LOADING_ON,
        payload: driverIds
    });
}

export const _driverLoadingOff = (driverIds, dispatch) => {
    driverIds.forEach(driverId => {
        // destroy timeouts created in driveries loading above
        if (driverLoaderTimeoutArray[driverId]) {
            clearTimeout(driverLoaderTimeoutArray[driverId]);
            driverLoaderTimeoutArray[driverId] = null;
        }
    });

    dispatch({
        type: DRIVER_LOADING_OFF,
        payload: driverIds
    });
}

export const _createDriverLoaderTimeout = (driverId, dispatch) => {
    if (driverId) {
        driverLoaderTimeoutArray[driverId] = setTimeout(() => {
            _getSingleDriverAndUpsert(driverId, dispatch)
        }, LOADER_TIMEOUT_MILLIS);
    }
}

const _getSingleDriverAndUpsert = (driverId, dispatch) => {

    if (isEmpty(driverId)) {
        return;
    }

    let portalCountryCode = store.getState().portal.data.cca2;

    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${API_USERS}/${portalCountryCode}/${driverId}`)
            .then(res => {
                dispatch({
                    type: GET_SINGLE_DRIVER_AND_UPSERT,
                    payload: res.data
                });
            }).then(() => {
                dispatch({
                    type: DRIVER_LOADING_OFF,
                    payload: [driverId]
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
                dispatch({
                    type: DRIVER_LOADING_OFF,
                    payload: [driverId]
                });
            });
    });
}

/*******  end *******/
export const whatsAppWindowOpen = (name, number) => dispatch => {
    return dispatch({
        type: WHATSAPP_WINDOW_OPEN,
        payload: { name: name, number: number }
    });
}

export const whatsAppWindowClose = () => dispatch => {
    return dispatch({
        type: WHATSAPP_WINDOW_CLOSE,
        payload: null
    });
}