import { GET_ERRORS, GET_UPLOAD_STATUSES } from './types';
import axios from 'axios';
import { API_BASE_URL, API_UPLOAD_BATCHES } from '../_constants/apiConstants';

export const getUploadStatuses = () => dispatch => {

    return axios.get(`${API_BASE_URL}${API_UPLOAD_BATCHES}/uploadStatuses`)
        .then(res => {
            dispatch({
                type: GET_UPLOAD_STATUSES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}