import { GET_STORES_SELECT } from './types';
import axios from 'axios';
import { API_BASE_URL, API_STORES } from '../_constants/apiConstants';
import isEmpty from '../_utils/isEmpty';
import store from '../_utils/store';

const apiBaseUrl = API_BASE_URL;
const apiStoresEndpoint = API_STORES;

export const getStoresSelect = (searchValue="") => dispatch => {
    if(!isEmpty(searchValue)) {
        searchValue = "&search="+searchValue;
    }

    let portalCountryCode = store.getState().portal.data.cca2;
    
    return axios.get(`${apiBaseUrl}${apiStoresEndpoint}/${portalCountryCode}?select=true${searchValue}`)
        .then(res => {
            dispatch({
                type: GET_STORES_SELECT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_STORES_SELECT,
                payload: []
            });
        });
}