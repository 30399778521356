import axios from 'axios';
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    EMAIL_SENT,
    SMS_SENT,
    RESET_PASSWORD_CONFIRMED,
    EMAIL_CONFIRMED,
    PHONE_NUMBER_CONFIRMED,
    IMPERSONATE_USER,
    SET_PREVIOUS_USER,
    SWITCH_TO_USER,
    LOGOUT,
    CHANGED_PASSWORD,
    LEAD_USER_REGISTERED,
    CLEAR_ERRORS_SESSION,
    SET_ACTIVE_DEVICE,
    CLEAR_CHANGED_PASSWORD,
    CHANGED_EMAIL,
    CLEAR_CHANGED_EMAIL,
    UPDATE_USER_CONTEXT
} from './types';

import setAuthToken from '../_utils/setAuthToken';
import {
    API_USERS,
    API_USER_PERMISSIONS
} from '../_constants/apiConstants';
import isEmpty from '../_utils/isEmpty';
import store from '../_utils/store';
import { JWT_TOKEN, MAIN_TOKEN, MAIN_USER } from '../_constants/localStorageConstants';

import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';

//Account APIs
const apiUsersEndpoint = API_USERS;
const apiUserPermissionsEndpoint = API_USER_PERMISSIONS;

axios.defaults.crossDomain = true;

export const registerUser = (user, navigationFunction, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/register`, user)
            .then(res => {
                const loginResponse = res.data;
                const token = loginResponse.jwtToken;
                const user = loginResponse.userPermissions;

                if (!isEmpty(setLocalStorageFunction)) {
                    setLocalStorageFunction(JWT_TOKEN, token);
                }

                setAuthToken(token);
                dispatch(setCurrentUser(user, token));

                if (!isEmpty(navigationFunction)) {
                    navigationFunction(loginResponse);
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const leadRegister = (requestData, callBack, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/leadRegister`, requestData)
            .then(res => {
                //Manual login
                if (!isEmpty(res.data.canLogin) && res.data.canLogin) {
                    dispatch({
                        type: LEAD_USER_REGISTERED,
                        payload: res.data
                    });
                }
                //Auto Login
                if (!isEmpty(res.data.jwtToken) && !isEmpty(res.data.userPermissions)) {
                    const token = res.data.jwtToken;
                    const user = res.data.userPermissions;

                    if (!isEmpty(setLocalStorageFunction)) {
                        setLocalStorageFunction(JWT_TOKEN, token);
                    }

                    setAuthToken(token);
                    dispatch(setCurrentUser(user, token));

                    if (!isEmpty(callBack)) {
                        callBack(requestData);
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const setCanLogin = (canLogin) => dispatch => {
    let payload = { canLogin: canLogin };
    dispatch({
        type: LEAD_USER_REGISTERED,
        payload: payload
    })
}

export const loginUser = (user, navigationFunction, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return _login(user, `${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/login`, dispatch, navigationFunction, setLocalStorageFunction);
    });
}

export const externalLogin = (user, navigationFunction, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return _login(user, `${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/externalLogin`, dispatch, navigationFunction, setLocalStorageFunction);
    });
}

export const twoFactorLogin = (user, navigationFunction, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return _login(user, `${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/twoFactorLogin`, dispatch, navigationFunction, setLocalStorageFunction);
    });
}

export const biometricLogin = (user, navigationFunction, setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return _login(user, `${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/biometricLogin`, dispatch, navigationFunction, setLocalStorageFunction);
    });
}

export const updateBiometrics = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.put(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/updateBiometrics`, request)
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const generateTwoFactorToken = (userHashId = "") => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/generateTwoFactorToken?hashId=${userHashId}`)
            .then(res => {
                dispatch({
                    type: SMS_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const resetPassword = (email) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/resetPassword/${email}`)
            .then(res => {
                dispatch({
                    type: EMAIL_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmResetPassword = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/confirmResetPassword`, request)
            .then(res => {
                dispatch({
                    type: RESET_PASSWORD_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const changePassword = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/changepassword`, request)
            .then(res => {
                dispatch({
                    type: CHANGED_PASSWORD,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearChangedPassword = () => dispatch => {
    dispatch({
        type: CLEAR_CHANGED_PASSWORD,
        payload: ''
    })
}

export const changeEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/changeEmail`, request)
            .then(res => {
                dispatch({
                    type: CHANGED_EMAIL,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearChangedEmail = () => dispatch => {
    dispatch({
        type: CLEAR_CHANGED_EMAIL,
        payload: ''
    })
}

export const clearSmsSent = () => dispatch => {
    dispatch({
        type: SMS_SENT,
        payload: ''
    })
}

export const verifyEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/verifyEmail`, request)
            .then(res => {
                dispatch({
                    type: EMAIL_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/confirmEmail`, request)
            .then(res => {
                dispatch({
                    type: EMAIL_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const verifyPhoneNumber = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/verifyPhoneNumber`, request)
            .then(res => {
                dispatch({
                    type: SMS_SENT,
                    payload: !isEmpty(res.data)
                });
                dispatch({
                    type: UPDATE_USER_CONTEXT,
                    payload: { ...res.data, user: res.data.user }
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmPhoneNumber = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/confirmPhoneNumber`, request)
            .then(res => {
                dispatch({
                    type: PHONE_NUMBER_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
                dispatch({
                    type: UPDATE_USER_CONTEXT,
                    payload: { ...res.data, user: res.data.user }
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

//pass in a bool for whether the verifaction sms has been sent or not
export const setSmsSent = (isSent) => dispatch => {
    dispatch({
        type: SMS_SENT,
        payload: isSent
    });
}

//pass in a bool for whether the auth confirmation has been sent
export const setPhoneNumberConfirmed = (isConfirmed) => dispatch => {
    dispatch({
        type: PHONE_NUMBER_CONFIRMED,
        payload: isConfirmed
    });
}

export const logoutInvalidSession = (navigationFunction, removeLocalStorageFunction) => dispatch => {
    dispatch({ type: LOGOUT, payload: {} });
    dispatch({ type: CLEAR_ERRORS_SESSION, payload: {} });
    if (!isEmpty(removeLocalStorageFunction)) {
        removeLocalStorageFunction(JWT_TOKEN);
        removeLocalStorageFunction(MAIN_TOKEN);
        removeLocalStorageFunction(MAIN_USER);
    }
    setAuthToken(false);
    if (!isEmpty(navigationFunction)) {
        navigationFunction();
    }
    _externalLogOut();
}

export const logoutUser = (navigationFunction, removeLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/logout`)
            .then(res => {
                _externalLogOut();
                dispatch({ type: LOGOUT, payload: {} });
                dispatch({ type: CLEAR_ERRORS_SESSION, payload: {} });
                if (!isEmpty(removeLocalStorageFunction)) {
                    removeLocalStorageFunction(JWT_TOKEN);
                    removeLocalStorageFunction(MAIN_TOKEN);
                    removeLocalStorageFunction(MAIN_USER);
                }
                setAuthToken(false);
                if (!isEmpty(navigationFunction)) {
                    navigationFunction();
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const getUserPermissions = (token) => dispatch => {
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${apiUserPermissionsEndpoint}`)
            .then(res => {
                let currentUser = { user: res.data, token: token };
                dispatch({
                    type: SET_CURRENT_USER,
                    payload: currentUser
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    })
}

export const impersonate = (userId, navigationFunction, setLocalStorageFunction, getLocalStorageFunction) => dispatch => {

    let currentStore = store.getState();
    let portalCountryCode = currentStore.portal.data.cca2;
    let userObject = { userId: userId };
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/impersonate`, userObject)
            .then(res => {
                const loginResponse = res.data;
                const token = loginResponse.jwtToken;
                const newUser = loginResponse.userPermissions;

                if (!isEmpty(getLocalStorageFunction) && !isEmpty(setLocalStorageFunction)) {
                    let oldToken = getLocalStorageFunction(JWT_TOKEN);
                    let oldUser = currentStore.auth.currentUser.user;

                    setLocalStorageFunction(JWT_TOKEN, token);
                    setLocalStorageFunction(MAIN_TOKEN, oldToken);
                    setLocalStorageFunction(MAIN_USER, JSON.stringify(oldUser));

                    setAuthToken(token);

                    dispatch({
                        type: IMPERSONATE_USER,
                        payload: { new: newUser, old: oldUser, token: token }
                    });

                    if (!isEmpty(navigationFunction)) {
                        navigationFunction();
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const switchToUser = (user, navigationFunction, setLocalStorageFunction, getLocalStorageFunction, removeLocalStorageFunction) => dispatch => {

    let mainToken = getLocalStorageFunction(MAIN_TOKEN);
    setLocalStorageFunction(JWT_TOKEN, mainToken);
    setAuthToken(mainToken);

    removeLocalStorageFunction(MAIN_TOKEN);
    removeLocalStorageFunction(MAIN_USER);

    dispatch({
        type: SWITCH_TO_USER,
        payload: { user: user, token: mainToken }
    });

    if (!isEmpty(navigationFunction)) {
        navigationFunction();
    }

}

export const setPreviousUser = user => dispatch => {
    dispatch({
        type: SET_PREVIOUS_USER,
        payload: user
    })
}

export const setCurrentUser = (user, token) => {
    let currentUser = { user: user, token: token };

    return {
        type: SET_CURRENT_USER,
        payload: currentUser
    }
}

export const setUserActiveDevice = (mobileDevice) => {
    return {
        type: SET_ACTIVE_DEVICE,
        payload: mobileDevice
    }
}

export const refreshToken = (setLocalStorageFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.post(`${apiBaseUrl}${apiUsersEndpoint}/${portalCountryCode}/refreshtoken`)
            .then(res => {
                const refreshTokenResponse = res.data;
                const token = refreshTokenResponse.jwtToken;
                const user = refreshTokenResponse.userPermissions;

                if (!isEmpty(setLocalStorageFunction)) {
                    setLocalStorageFunction(JWT_TOKEN, token);
                }

                setAuthToken(token);
                dispatch(setCurrentUser(user, token));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

function _login(user, endpoint, dispatch, navigationFunction, setLocalStorageFunction) {
    return axios.post(endpoint, user)
        .then(res => {
            const loginResponse = res.data;
            const token = loginResponse.jwtToken;
            const user = loginResponse.userPermissions;

            setAuthToken(token);

            if (!isEmpty(setLocalStorageFunction)) {
                setLocalStorageFunction(JWT_TOKEN, token);
            }

            dispatch(setCurrentUser(user, token));

            if (!isEmpty(navigationFunction)) {
                navigationFunction(loginResponse);
                if (loginResponse.requiresTwoFactor) {
                    dispatch({ type: SMS_SENT, payload: true });
                }
            }
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}

function _externalLogOut() {
    if (!isEmpty(window.gapi) && !isEmpty(window.gapi.auth2) && !isEmpty(window.gapi.auth2.getAuthInstanc)) {
        let auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            console.info('Google signed out.');
        });
    }
    if (!isEmpty(window.FB) && !isEmpty(window.FB.logout)) {
        window.FB.logout(function (response) {
            console.info('Facebook logged out.');
        });
    }
}