/* eslint-disable no-unused-vars */
import {
    GET_STORE_DRIVERS,
    CLEAR_STORE_DRIVERS,
    CLEAR_STORE_DRIVERS_SUCCESS,
    DELETE_STORE_DRIVERS,
    POST_STORE_DRIVERS,
    SET_LOADING_STORE_DRIVERS
} from '../_actions/types';
import { POST_SUCCESS, DELETE_SUCCESS } from '../_constants/stringConstants';
import isEmpty from '../_utils/isEmpty';

const initialState = {
    data: {},
    success: null,
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_STORE_DRIVERS: //here we key items by id for O(1) lookup/update/delete from redux
            return {
                ...state,
                data: {
                    ...action.payload, items: action.payload.items.reduce((obj, item) => {
                        obj[`#${item.id}`] = item; //here we key on #id so as not to lose ordering from backend
                        return obj;
                    }, {})
                },
                search: !isEmpty(action.search) ? action.search : "",
                loading: false
            }
        case POST_STORE_DRIVERS:
            return {
                ...state,
                success: `${POST_SUCCESS} driver store relationships`,
                loading: false
            }
        case DELETE_STORE_DRIVERS:
            return {
                ...state,
                success: `${DELETE_SUCCESS} ${action.payload.length} drivers from store.`,
                loading: false
            }
        case SET_LOADING_STORE_DRIVERS:
            return {
                ...state,
                loading: action.payload
            }
        case CLEAR_STORE_DRIVERS_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_STORE_DRIVERS:
            return {
                ...state,
                data: {},
                success: ''
            }
        default:
            return state;
    }
}