import { GET_AVAILABLE_DATES, GET_AVAILABLE_TIMESLOTS, GET_ERRORS } from './types';
import axios from 'axios';
import { API_BASE_URL, API_DELIVERY_WINDOWS } from '../_constants/apiConstants';
import store from '../_utils/store';

const apiBaseUrl = API_BASE_URL;
const apiDeliveryWindowsEndpoint = API_DELIVERY_WINDOWS;
const getAvailableDatesEndpoint = "getavailabledates";
const getAvailableTimeslotsEndpoint = "getavailabletimeslots";

export const getAvailableDates = () => dispatch => {

    let portalCountryCode = store.getState().portal.data.cca2;

    return axios.get(`${apiBaseUrl}${apiDeliveryWindowsEndpoint}/${getAvailableDatesEndpoint}/${portalCountryCode}`)
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_DATES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}

export const getAvailableTimeslots = (dateString) => dispatch => {

    let portalCountryCode = store.getState().portal.data.cca2;

    return axios.get(`${apiBaseUrl}${apiDeliveryWindowsEndpoint}/${getAvailableTimeslotsEndpoint}/${portalCountryCode}?localDate=${dateString}`)
        .then(res => {
            dispatch({
                type: GET_AVAILABLE_TIMESLOTS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}