import { GET_ERRORS, CLEAR_ERRORS, CLEAR_ERRORS_SUCCESS, CLEAR_ERRORS_SESSION } from '../_actions/types';
import isEmpty from '../_utils/isEmpty';
import { ERROR_DEFAULT, ERROR_500, ERROR_401, ERROR_403, ERROR_404, ERROR_CONNECTION, ERROR_413 } from '../_constants/stringConstants';


const initialState = {
    message: '',
    errors: '',
    status: '',
    isConnectionError: false,
    isSessionError: false,
    forceConnectionError: false,
    success: null
};

export default function (state = initialState, action) {

    let errorMessage = ERROR_DEFAULT;
    let errorsArray = '';
    let status = 500;

    switch (action.type) {
        //set our error messages and error properties
        case GET_ERRORS:

            if (!isEmpty(action.payload)) {

                //force error app inlitiaizer
                let isSessionError = false;

                //check for 404 error status
                if (!isEmpty(action.payload.status) && action.payload.status === 404 && isEmpty(action.payload.data)) {
                    let url = "";
                    if (!isEmpty(action.payload.config) && !isEmpty(action.payload.config.url)) {
                        url = action.payload.config.url;
                    }
                    errorMessage = `${ERROR_404}: ${url}`;
                }

                //check for 500 error status
                else if (!isEmpty(action.payload.status) && action.payload.status === 500) {

                    let errorCode = !isEmpty(action.payload.data.message) ? " Error code: " + action.payload.data.message + "." : "";
                    errorMessage = ERROR_500 + errorCode;
                }

                //check for 401 error status
                else if (!isEmpty(action.payload.status) && action.payload.status === 401 && isEmpty(action.payload.data)) {
                    errorMessage = ERROR_401;
                }

                //check for 403 error status
                else if (!isEmpty(action.payload.status) && action.payload.status === 403 && isEmpty(action.payload.data)) {
                    errorMessage = ERROR_403;
                }

                //check for 403 error status
                else if (!isEmpty(action.payload.status) && action.payload.status === 413 && isEmpty(action.payload.data)) {
                    errorMessage = ERROR_413;
                }

                //check for 423 error status
                else if (!isEmpty(action.payload.status) && action.payload.status === 440) {
                    errorMessage = "";
                    isSessionError = true;
                }

                //check for all other errors
                else if (!isEmpty(action.payload.data)) {
                    if (!isEmpty(action.payload.data.message)) {
                        errorMessage = action.payload.data.message;
                    }

                    if (!isEmpty(action.payload.data.errors)) {
                        errorsArray = action.payload.data.errors;
                    }
                }

                status = action.payload.status;

                return { ...state, message: errorMessage, errors: errorsArray, status: status, isConnectionError: false, isSessionError: isSessionError, success: true };
            } else {
                //here we are unable to connect at all to the backend (i.e., no payload returned at all)
                errorMessage = ERROR_CONNECTION;
                return { ...state, message: errorMessage, isConnectionError: true, forceConnectionError: true, success: true };
            }



        //clear the error messages
        case CLEAR_ERRORS:
            return { ...state, message: '', errors: '', status: '', isConnectionError: false, isSessionError: state.isSessionError, forceConnectionError: state.forceConnectionError, success: null };

        //clear the success key for errors so we know when a new error comes back from the server
        case CLEAR_ERRORS_SUCCESS:
            return { ...state, success: null };

        case CLEAR_ERRORS_SESSION:
            return { ...state, isSessionError: false };

        default:
            return state;
    }
}
