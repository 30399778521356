import { GET_ERRORS, GET_RATE_UPLOADS, PUT_RATE_UPLOADS, CANCEL_RATE_UPLOAD, CLEAR_RATE_UPLOADS, CLEAR_RATE_UPLOADS_SUCCESS } from './types';
import axios from 'axios';
import { API_UPLOAD_BATCHES } from '../_constants/apiConstants';
import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';
import store from 'wumdrophubsreactshared/_utils/store';
import buildQueryMeta from 'wumdrophubsreactshared/_utils/buildQueryMeta';
import serializeQuery from 'wumdrophubsreactshared/_utils/serializeQuery';
import buildResponseMeta from 'wumdrophubsreactshared/_utils/buildResponseMeta';

export const getRateUpload = (rateUploadId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${API_UPLOAD_BATCHES}/${portalCountryCode}/Uploads/Rate/${rateUploadId}`)
            .then(res => {
                dispatch({
                    type: GET_RATE_UPLOADS,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const getBatchRateUploadsPage = (uploadBatchId = "", meta = {}) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let query = buildQueryMeta(meta);
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.get(`${apiBaseUrl}${API_UPLOAD_BATCHES}/${portalCountryCode}/${uploadBatchId}/Rate?${serializeQuery(query)}`)
            .then(res => {
                res = buildResponseMeta(res, meta);
                dispatch({
                    type: GET_RATE_UPLOADS,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const updateRateUpload = (rateUploadId, rateUpload) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.put(`${apiBaseUrl}${API_UPLOAD_BATCHES}/${portalCountryCode}/uploads/Rate/${rateUploadId}`, rateUpload)
            .then(res => {
                dispatch({
                    type:PUT_RATE_UPLOADS,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const cancelRateUpload = (rateUploadId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return axios.delete(`${apiBaseUrl}${API_UPLOAD_BATCHES}/${portalCountryCode}/Rate/${rateUploadId}`)
            .then(res => {
                dispatch({
                    type: CANCEL_RATE_UPLOAD,
                    payload:  res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearRateUploads = () => dispatch => {
    dispatch({
        type: CLEAR_RATE_UPLOADS,
        payload: ''
    });
}

export const clearRateUploadsSuccess = () => dispatch => {
    dispatch({
        type: CLEAR_RATE_UPLOADS_SUCCESS,
        payload: ''
    });
}


