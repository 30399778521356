import { POST_JOBS_WORKFLOW, PUT_JOBS_WORKFLOW, POST_NOTIFY_JOB_DELAY, CLEAR_JOBS_WORKFLOW, SET_LOADING_JOBS_WORKFLOW, GET_JOB_ROUTES } from '../_actions/types';
import { POST_SUCCESS, PUT_SUCCESS } from '../_constants/stringConstants';

const initialState = {
    data: {},
    success: "",
    loading: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_JOB_ROUTES:
            return {
                ...state,
                data: action.payload
            }
        case POST_JOBS_WORKFLOW:
            return {
                ...state,
                data: action.payload,
                success: `${POST_SUCCESS} job`,
                loading: false
            }
        case PUT_JOBS_WORKFLOW:
            return {
                ...state,
                data: action.payload,
                success: `${PUT_SUCCESS} job`,
                loading: false
            }
        case POST_NOTIFY_JOB_DELAY:
            return {
                ...state,
                data: action.payload,
                success: "Successfully sent notifications",
                loading: false
           }    
        case CLEAR_JOBS_WORKFLOW:
            return {
                ...state,
                data: {},
                success: '',
                loading: false
            }
        case SET_LOADING_JOBS_WORKFLOW:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}