/* authenticaiton */
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_ERRORS_SUCCESS = "CLEAR_ERRORS_SUCCESS";
export const CLEAR_ERRORS_SESSION = "CLEAR_ERRORS_SESSION";
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const EMAIL_SENT = "EMAIL_SENT";
export const SMS_SENT = "SMS_SENT";
export const RESET_PASSWORD_CONFIRMED = "RESET_PASSWORD_CONFIRMED";
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD';
export const CLEAR_CHANGED_PASSWORD = 'CLEAR_CHANGED_PASSWORD';
export const CHANGED_EMAIL = 'CHANGED_EMAIL';
export const CLEAR_CHANGED_EMAIL = 'CLEAR_CHANGED_EMAIL';
export const EMAIL_CONFIRMED = "EMAIL_CONFIRMED";
export const PHONE_NUMBER_CONFIRMED = "PHONE_NUMBER_CONFIRMED";
export const SET_PREVIOUS_USER = "SET_PREVIOUS_USER";
export const SWITCH_TO_USER = "SWITCH_TO_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER_CONTEXT = "UPDATE_USER_CONTEXT"; //this is performed by the signal R middleware to update user permissions/context via userpermissions change events sent from the backend
export const LEAD_USER_REGISTERED = "LEAD_USER_REGISTERED";
export const SET_ACTIVE_DEVICE = "SET_ACTIVE_DEVICE";

export const GENERATE_HAND_OVER_QR_CODE = "GENERATE_HAND_OVER_QR_CODE";
export const CONFIRM_HAND_OVER_QR_CODE = "CONFIRM_HAND_OVER_QR_CODE";
export const CLEAR_HAND_OVER_QR_CODE = "CLEAR_HAND_OVER_QR_CODE";

/* applications workflows */
export const GET_PARTNER_APPLICATIONS_WORKFLOW = 'GET_PARTNER_APPLICATIONS_WORKFLOW';
export const CLEAR_PARTNER_APPLICATIONS_WORKFLOW = 'CLEAR_PARTNER_APPLICATIONS_WORKFLOW';
export const SET_LOADING_PARTNER_APPLICATIONS = 'SET_LOADING_PARTNER_APPLICATIONS';
export const SET_ACTIVE_STEP_PARTNER_APPLICATIONS = 'SET_ACTIVE_STEP_PARTNER_APPLICATIONS';
export const GET_PARTNER_APPLICATIONS_WORKFLOW_NEXT = 'GET_PARTNER_APPLICATIONS_WORKFLOW_NEXT';
export const CLEAR_PARTNER_APPLICATIONS_WORKFLOW_SUCCESS = 'CLEAR_PARTNER_APPLICATIONS_WORKFLOW_SUCCESS';

/* application invites */
export const GET_APPLICATION_INVITES = "GET_APPLICATION_INVITES";
export const CLEAR_APPLICATION_INVITES = "CLEAR_APPLICATION_INVITES";
export const GET_APPLICATION_INVITES_SUCCESS = "GET_APPLICATION_INVITES_SUCCESS";
export const CLEAR_APPLICATION_INVITES_SUCCESS = "CLEAR_APPLICATION_INVITES_SUCCESS";
export const SET_LOADING_APPLICATION_INVITES = "SET_LOADING_APPLICATION_INVITES";

/* assistants */
export const GET_ASSISTANTS = "GET_ASSISTANTS";
export const SET_LOADING_ASSISTANTS = "SET_LOADING_ASSISTANTS";

/* business applications and workflow */
export const GET_BUSINESS_APPLICATIONS_WORKFLOW = "GET_BUSINESS_APPLICATIONS_WORKFLOW";
export const GET_BUSINESS_APPLICATIONS_WORKFLOW_NEXT = "GET_BUSINESS_APPLICATIONS_WORKFLOW_NEXT";
export const SET_LOADING_BUSINESS_APPLICATIONS = "SET_LOADING_BUSINESS_APPLICATIONS";
export const SET_ACTIVE_STEP_BUSINESS_APPLICATIONS = "SET_ACTIVE_STEP_BUSINESS_APPLICATIONS";
export const CLEAR_BUSINESS_APPLICATIONS_WORKFLOW = "CLEAR_BUSINESS_APPLICATIONS_WORKFLOW";
export const CLEAR_BUSINESS_APPLICATIONS_WORKFLOW_SUCCESS = "CLEAR_BUSINESS_APPLICATIONS_WORKFLOW_SUCCESS";

/* business applications */
export const GET_BUSINESS_APPLICATIONS = "GET_BUSINESS_APPLICATIONS";
export const CLEAR_BUSINESS_APPLICATIONS = "CLEAR_BUSINESS_APPLICATIONS";
export const CLEAR_BUSINESS_APPLICATIONS_SUCCESS = "CLEAR_BUSINESS_APPLICATIONS_SUCCESS";
export const DELETE_BUSINESS_COMPANIES = "DELETE_BUSINESS_COMPANIES";

/* partner applications */
export const GET_PARTNER_APPLICATIONS = "GET_PARTNER_APPLICATIONS";
export const CLEAR_PARTNER_APPLICATIONS = "CLEAR_PARTNER_APPLICATIONS"
export const CLEAR_PARTNER_APPLICATIONS_SUCCESS = "CLEAR_PARTNER_APPLICATIONS_SUCCESS";
export const DELETE_PARTNER_COMPANIES = "DELETE_PARTNER_COMPANIES";

/* driver applications */
export const GET_DRIVER_APPLICATIONS = "GET_DRIVER_APPLICATIONS";
export const CLEAR_DRIVER_APPLICATIONS = "CLEAR_DRIVER_APPLICATIONS"
export const CLEAR_DRIVER_APPLICATIONS_SUCCESS = "CLEAR_DRIVER_APPLICATIONS_SUCCESS";
export const DELETE_DRIVER_APPLICATIONS = "DELETE_DRIVER_APPLICATIONS";

/* assistant applications */
export const GET_ASSISTANT_APPLICATIONS = "GET_ASSISTANT_APPLICATIONS";
export const CLEAR_ASSISTANT_APPLICATIONS = "CLEAR_ASSISTANT_APPLICATIONS"
export const CLEAR_ASSISTANT_APPLICATIONS_SUCCESS = "CLEAR_ASSISTANT_APPLICATIONS_SUCCESS";
export const DELETE_ASSISTANT_APPLICATIONS = "DELETE_ASSISTANT_APPLICATIONS";

/* vehicle applications */
export const GET_VEHICLE_APPLICATIONS = "GET_VEHICLE_APPLICATIONS";
export const CLEAR_VEHICLE_APPLICATIONS = "CLEAR_VEHICLE_APPLICATIONS"
export const CLEAR_VEHICLE_APPLICATIONS_SUCCESS = "CLEAR_VEHICLE_APPLICATIONS_SUCCESS";
export const DELETE_VEHICLE_APPLICATIONS = "DELETE_VEHICLE_APPLICATIONS";

/* navigation */
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

export const LEFT_SIDEBAR_TOGGLE = "LEFT_SIDEBAR_TOGGLE";
export const RIGHT_SIDEBAR_TOGGLE = "RIGHT_SIDEBAR_TOGGLE";
export const USER_MENU_TOGGLE = "USER_MENU_TOGGLE";
export const DELIVERY_WIZARD_TOGGLE = "DELIVERY_WIZARD_TOGGLE";
export const DELIVERY_DETAILS_TOGGLE = "DELIVERY_DETAILS_TOGGLE";
export const JOB_DETAILS_TOGGLE = "JOB_DETAILS_TOGGLE";
export const TASK_MODAL_TOGGLE = "TASK_MODAL_TOGGLE";
export const JOB_TASK_LIST_TOGGLE = "JOB_TASK_LIST_TOGGLE";
export const CLEAR_ALL_TASK_LIST_TOGGLES = "CLEAR_ALL_TASK_LIST_TOGGLES"
export const JOB_TASK_LIST_MULTIPLE_TOGGLE = "JOB_TASK_LIST_MULTIPLE_TOGGLE"
export const BARCODE_SCANNER_TOGGLE = "BARCODE_SCANNER_TOGGLE";
export const CASH_DEPOSIT_MODAL_TOGGLE = "CASH_DEPOSIT_MODAL_TOGGLE";
export const JOB_ROUTE_TOGGLE = "JOB_ROUTE_TOGGLE";
export const WHATSAPP_TOGGLE = "WHATSAPP_TOGGLE";

export const DASHBOARD_FILTER = "DASHBOARD_FILTER";
export const CLEAR_DASHBOARD_FILTERS = "CLEAR_DASHBOARD_FILTERS";
export const LEFT_SIDEBAR_RESIZE = "LEFT_SIDEBAR_RESIZE";
export const RIGHT_SIDEBAR_RESIZE = "RIGHT_SIDEBAR_RESIZE";
export const DASHBOARD_LEFT_TABS_VALUE = "DASHBOARD_LEFT_TABS_VALUE";
export const DASHBOARD_RIGHT_TABS_VALUE = "DASHBOARD_RIGHT_TABS_VALUE";
export const SET_DELIVERY_WIZARD_PROPS = "SET_DELIVERY_WIZARD_PROPS";
export const SET_ADMIN_TABLE_META = "SET_ADMIN_TABLE_META";
export const CLEAR_ADMIN_TABLE_META = "CLEAR_ADMIN_TABLE_META";
export const REFRESH_DRIVER_LIST_ON = "REFRESH_DRIVER_LIST_ON";
export const REFRESH_DRIVER_LIST_OFF = "REFRESH_DRIVER_LIST_OFF";
export const SET_DELIVERY_ID_FILTER = "SET_DELIVERY_ID_FILTER";
export const CLEAR_DELIVERY_ID_FILTERS = "CLEAR_DELIVERY_ID_FILTERS";
export const SET_JOB_ID_FILTER = "SET_JOB_ID_FILTER";
export const CLEAR_JOB_ID_FILTERS = "CLEAR_JOB_ID_FILTERS";
export const SET_DRIVER_ID_FILTER = "SET_DRIVER_ID_FILTER";
export const CLEAR_DRIVER_ID_FILTERS = "CLEAR_DRIVER_ID_FILTERS";
export const SET_DELIVERY_SEARCH_TERM = "SET_DELIVERY_SEARCH_TERM";
export const CLEAR_DELIVERY_SEARCH_TERM = "CLEAR_DELIVERY_SEARCH_TERM";
export const SET_TASK_MODAL_DATA = "SET_TASK_MODAL_DATA";
export const WHATSAPP_WINDOW_OPEN = "WHATSAPP_WINDOW_OPEN";
export const WHATSAPP_WINDOW_CLOSE = "WHATSAPP_WINDOW_CLOSE";
export const SET_QSR_MODE = "SET_QSR_MODE";


/* territories */
export const GET_TERRITORIES = "GET_TERRITORIES";
export const POST_TERRITORIES = "POST_TERRITORIES";
export const PUT_TERRITORIES = "PUT_TERRITORIES";
export const DELETE_TERRITORIES = "DELETE_TERRITORIES";
export const CLEAR_TERRITORIES = "CLEAR_TERRITORIES";
export const CLEAR_TERRITORIES_SUCCESS = "CLEAR_TERRITORIES_SUCCESS";
export const GET_TERRITORIES_SELECT = "GET_TERRITORIES_SELECT";
export const SET_LOADING_TERRITORIES_SELECT = "SET_LOADING_TERRITORIES_SELECT";
export const GET_TERRITORY_PERMISSIONS = "GET_TERRITORIES_PERMISSIONS";
export const GET_AREA_INTERACTIONS = "GET_AREA_INTERACTIONS";

/* territory areas */
export const GET_TERRITORIES_AREAS = "GET_TERRITORIES_AREAS";
export const POST_TERRITORIES_AREAS = "POST_TERRITORIES_AREAS";
export const PUT_TERRITORIES_AREAS = "PUT_TERRITORIES_AREAS";
export const DELETE_TERRITORIES_AREAS = "DELETE_TERRITORIES_AREAS";
export const CLEAR_TERRITORIES_AREAS = "CLEAR_TERRITORIES_AREAS";
export const CLEAR_TERRITORIES_AREAS_SUCCESS = "CLEAR_TERRITORIES_AREAS_SUCCESS";

/* territory exceptions */
export const GET_TERRITORIES_EXCEPTIONS = "GET_TERRITORIES_EXCEPTIONS";
export const POST_TERRITORIES_EXCEPTIONS = "POST_TERRITORIES_EXCEPTIONS";
export const PUT_TERRITORIES_EXCEPTIONS = "PUT_TERRITORIES_EXCEPTIONS";
export const DELETE_TERRITORIES_EXCEPTIONS = "DELETE_TERRITORIES_EXCEPTIONS";
export const CLEAR_TERRITORIES_EXCEPTIONS = "CLEAR_TERRITORIES_EXCEPTIONS";
export const CLEAR_TERRITORIES_EXCEPTIONS_SUCCESS = "CLEAR_TERRITORIES_EXCEPTIONS_SUCCESS";

/* areas */
//this is specifically for getting a single area associated with a territory or a hub
export const GET_AREA = "GET_AREA";
export const CLEAR_AREA = "CLEAR_AREA";
export const POST_AREA = "POST_AREA";
export const PUT_AREA = "PUT_AREA";

/* EXCEPTION */
//this is specifically for getting a single EXCEPTION associated with a territory
export const GET_EXCEPTION = "GET_EXCEPTION";
export const CLEAR_EXCEPTION = "CLEAR_EXCEPTION";
export const POST_EXCEPTION = "POST_EXCEPTION";
export const PUT_EXCEPTION = "PUT_EXCEPTION";

/* users */
export const GET_USERS = "GET_USERS";
export const GET_USERS_BY_ID = "GET_USERS_BY_ID";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_USERS_SUCCESS = "CLEAR_USERS_SUCCESS";
export const PUT_USERS = "PUT_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const POST_USERS = "POST_USERS";
export const GET_USERS_AGGREGATIONS = "GET_USERS_AGGREGATIONS";
export const GENERATE_USER_API = "GENERATE_USER_API";
export const SET_LOADING_USERS = "SET_LOADING_USERS";
export const GET_SINGLE_DRIVER_AND_UPSERT = "GET_SINGLE_DRIVER_AND_UPSERT";
export const SYNC_USER = "SYNC_USER";
export const VERIFY_USER = "VERIFY_USER";

export const GET_USER_SESSION = "GET_USER_SESSION";
export const CLEAR_USER_SESSION  = "CLEAR_USER_SESSION";


/* companies */
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SELECT = "GET_COMPANIES_SELECT";
export const POST_COMPANIES = "POST_COMPANIES";
export const PUT_COMPANIES = "PUT_COMPANIES";
export const DELETE_COMPANIES = "DELETE_COMPANIES";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";
export const CLEAR_COMPANIES_SUCCESS = "CLEAR_COMPANIES_SUCCESS";


//company communications
export const GET_COMPANY_COMMUNICATIONS_HEADINGS = "GET_COMPANY_COMMUNICATIONS_HEADINGS";
export const GET_COMPANY_COMMUNICATIONS = "GET_COMPANY_COMMUNICATIONS";
export const PUT_COMPANY_COMMUNICATIONS = "PUT_COMPANY_COMMUNICATIONS";
export const CLEAR_COMPANY_COMMUNICATIONS = "CLEAR_COMPANY_COMMUNICATIONS";
export const CLEAR_COMPANY_COMMUNICATIONS_SUCCESS = "CLEAR_COMPANY_COMMUNICATIONS_SUCCESS";

/* deliveries */
export const GET_DELIVERIES = "GET_DELIVERIES";
// export const GET_DELIVERIES_SELECT = "GET_DELIVERIES_SELECT";
export const GET_DELIVERIES_BY_ID = "GET_DELIVERIES_BY_ID";
export const GET_DELIVERIES_BY_HASH_ID = "GET_DELIVERIES_BY_HASH_ID";
export const POST_DELIVERIES = "POST_DELIVERIES";
export const PUT_DELIVERIES = "PUT_DELIVERIES";
export const PUT_DELIVERIES_DUE_DATE="PUT_DELIVERIES_DUE_DATE";
export const DELETE_DELIVERIES = "DELETE_DELIVERIES";
export const CLEAR_DELIVERIES = "CLEAR_DELIVERIES";
export const CLEAR_DELIVERIES_SUCCESS = "CLEAR_DELIVERIES_SUCCESS";
export const GET_DELIVERIES_AGGREGATIONS = "GET_DELIVERIES_AGGREGATIONS"; //deliveries aggregations from elastic
export const GET_DELIVERY_DATE_TYPES = "GET_DELIVERY_DATE_TYPES";
export const GET_SINGLE_DELIVERY_AND_UPSERT = "GET_SINGLE_DELIVERY_AND_UPSERT";
export const SET_LOADING_DELIVERIES = "SET_LOADING_DELIVERIES";
export const SYNC_DELIVERY = "SYNC_DELIVERY";

/* delivery items */
export const GET_DELIVERY_ITEMS = "GET_DELIVERY_ITEMS";
export const CLEAR_DELIVERY_ITEMS = "CLEAR_DELIVERY_ITEMS";
export const SET_LOADING_DELIVERY_ITEMS = "SET_LOADING_DELIVERY_ITEMS";
export const UPDATE_DELIVERY_ITEMS = "UPDATE_DELIVERY_ITEMS";

/* jobs */
export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_BY_ID = "GET_JOBS_BY_ID";
export const POST_JOBS = "POST_JOBS";
export const PUT_JOBS = "PUT_JOBS";
export const DELETE_JOBS = "DELETE_JOBS";
export const CLEAR_JOBS = "CLEAR_JOBS";
export const CLEAR_JOBS_SUCCESS = "CLEAR_JOBS_SUCCESS";
export const GET_JOBS_AGGREGATIONS = "GET_JOBS_AGGREGATIONS";
export const GET_MORE_JOBS = "GET_MORE_JOBS";
export const GET_JOB_DATE_TYPES = "GET_JOB_DATE_TYPES";
export const GET_SINGLE_JOB_AND_UPSERT = "GET_SINGLE_JOB_AND_UPSERT";
export const CLEAR_JOBS_LOADED = "CLEAR_JOBS_LOADED";
export const SET_LOADING_JOBS = "SET_LOADING_JOBS";
export const SYNC_JOB = "SYNC_JOB";
export const SYNC_DRIVER_JOBS = "SYNC_DRIVER_JOBS";

export const SET_JOB_DETAILS = "SET_JOB_DETAILS";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const GET_JOB_DETAILS_DRIVER = "GET_JOB_DETAILS_DRIVER";
export const GET_JOB_DETAILS_DELIVERIES = "GET_JOB_DETAILS_DELIVERIES";
export const GET_JOB_DETAILS_TRANSACTION_ITEMS = "GET_JOB_DETAILS_TRANSACTION_ITEMS";
export const CLEAR_JOB_DETAILS = "CLEAR_JOB_DETAILS";
export const SET_LOADING_JOB_DETAILS_DELIVERIES = "SET_LOADING_JOB_DETAILS_DELIVERIES";

/* jobs workflow */
export const CLEAR_JOBS_WORKFLOW = "CLEAR_JOBS_WORKFLOW";
export const POST_JOBS_WORKFLOW = "POST_JOBS_WORKFLOW";
export const PUT_JOBS_WORKFLOW = "PUT_JOBS_WORKFLOW";
export const SET_LOADING_JOBS_WORKFLOW = "SET_LOADING_JOBS_WORKFLOW";
export const POST_NOTIFY_JOB_DELAY = "POST_NOTIFY_JOB_DELAY";
export const GET_JOB_ROUTES = "GET_JOB_ROUTES";

export const GET_JOB_COST = "GET_JOB_COST";
export const CLEAR_JOB_COST = "CLEAR_JOB_COST";

export const GET_PARCEL_BARCODES = "GET_PARCEL_BARCODES";
export const SET_CHECKED_PARCELS = "SET_CHECKED_PARCELS";
export const CLEAR_PARCEL_BARCODES = "CLEAR_PARCEL_BARCODES";
export const CLEAR_CHECKED_PARCELS = "CLEAR_CHECKED_PARCELS";

/* jobs worfklow mobile */
export const PUT_JOBS_WORKFLOW_MOBILE = "PUT_JOBS_WORKFLOW_MOBILE";

/* portal/ countries */
export const GET_COUNTRY_BY_COUNTRYCODE = "GET_COUNTRY_BY_COUNTRYCODE";
export const GET_COUNTRIES = "GET_COUNTRIES";

/* tasks workflow */
export const CLEAR_TASKS_WORKFLOW_SUCCESS = "CLEAR_TASKS_WORKFLOW_SUCCESS";
export const PUT_TASKS_WORKFLOW = "PUT_TASKS_WORKFLOW";
export const GET_TASKS_WORKFLOW = "GET_TASKS_WORKFLOW";

/* quotes */
export const GET_QUOTES = "GET_QUOTES";
export const CLEAR_QUOTES = "CLEAR_QUOTES";
export const CLEAR_QUOTES_SUCCESS = "CLEAR_QUOTES_SUCCESS";
export const RELOAD_QUOTE = "RELOAD_QUOTE";
export const RELOAD_QUOTE_CLEAR = "RELOAD_QUOTE_CLEAR";
export const GET_QUOTE_DETAILS = "GET_QUOTE_DETAILS";
export const CLEAR_QUOTE_DETAILS = "CLEAR_QUOTE_DETAILS";
export const GET_QUOTE_ACCOUNTS = "GET_QUOTE_ACCOUNTS";
export const SET_LOADING_QUOTES = "SET_LOADING_QUOTES";

/* tasks */
export const GET_TASKS = "GET_TASKS";
export const CONCAT_TASKS = "CONCAT_TASKS";
export const POST_TASKS = "POST_TASKS";
export const PUT_TASKS = "PUT_TASKS";
export const DELETE_TASKS = "DELETE_TASKS";
export const CLEAR_TASKS = "CLEAR_TASKS";
export const CLEAR_JOB_TASK = "CLEAR_JOB_TASK"
export const CLEAR_TASKS_SUCCESS = "CLEAR_TASKS_SUCCESS";
export const SET_LOADING_TASKS = "SET_LOADING_TASKS";
export const UPDATE_JOB_TASKS = "UPDATE_JOB_TASKS";

/* fundsReceived */
export const GET_FUNDS_RECEIVED = "GET_FUNDS_RECEIVED";
export const CLEAR_FUNDS_RECEIVED = "CLEAR_FUNDS_RECEIVED";
export const CLEAR_FUNDS_RECEIVED_SUCCESS = "CLEAR_FUNDS_RECEIVED_SUCCESS";
export const DELETE_FUNDS_RECEIVED = "DELETE_FUNDS_RECEIVED";
export const POST_FUNDS_RECEIVED = "POST_FUNDS_RECEIVED";
export const PUT_FUNDS_RECEIVED = "PUT_FUNDS_RECEIVED";

export const GET_FUNDS_ALLOCATION = "GET_FUNDS_ALLOCATION";
export const CLEAR_FUNDS_ALLOCATION = "CLEAR_FUNDS_ALLOCATION";
export const POST_FUNDS_ALLOCATION = "POST_FUNDS_ALLOCATION";

/* bank accounts */
export const POST_BANK_ACCOUNTS = "POST_BANK_ACCOUNTS";
export const PUT_BANK_ACCOUNTS = "PUT_BANK_ACCOUNTS";
export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const DELETE_BANK_ACCOUNTS = "DELETE_BANK_ACCOUNTS";
export const CLEAR_BANK_ACCOUNTS = "CLEAR_BANK_ACCOUNTS";
export const CLEAR_BANK_ACCOUNTS_SUCCESS = "CLEAR_BANK_ACCOUNTS_SUCCESS";

/* withdrawals */
export const POST_WITHDRAWALS = "POST_WITHDRAWALS";
export const PUT_WITHDRAWALS = "PUT_WITHDRAWALS";
export const GET_WITHDRAWALS = "GET_WITHDRAWALS";
export const CLEAR_WITHDRAWALS = "CLEAR_WITHDRAWALS";
export const CLEAR_WITHDRAWALS_SUCCESS = "CLEAR_WITHDRAWALS_SUCCESS";

/* transactions/ items */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const CLEAR_TRANSACTIONS_SUCCESS = "CLEAR_TRANSACTIONS_SUCCESS";
export const POST_TRANSACTIONS = "POST_TRANSACTIONS";
export const REVERSE_TRANSACTIONS = "REVERSE_TRANSACTIONS";
export const PUT_TRANSACTIONS = "PUT_TRANSACTIONS";
export const CANCEL_TRANSACTIONS = "CANCEL_TRANSACTIONS";
export const COMPLETE_TRANSACTIONS = "COMPLETE_TRANSACTIONS";
export const GET_TRANSACTION_ITEMS = "GET_TRANSACTION_ITEMS";
export const CLEAR_TRANSACTION_ITEMS = "CLEAR_TRANSACTION_ITEMS";
export const CLEAR_TRANSACTION_ITEMS_SUCCESS = "CLEAR_TRANSACTION_ITEMS_SUCCESS";
export const POST_TRANSACTION_ITEMS = "POST_TRANSACTIONS_ITEMS";
export const SET_LOADING_TRANSACTIONS = "SET_LOADING_TRANSACTIONS";
export const SET_LOADING_TRANSACTION_ITEMS = "SET_LOADING_TRANSACTION_ITEMS";

/* banks */
export const GET_BANKS = "GET_BANKS";
export const SET_LOADING_BANKS = "SET_LOADING_BANKS";

/* bank account types */
export const GET_BANK_ACCOUNT_TYPES = "GET_BANK_ACCOUNT_TYPES";
export const SET_LOADING_BANK_ACCOUNT_TYPES = "SET_LOADING_BANK_ACCOUNT_TYPES";

/* reports */
export const GET_REPORT_CUSTOMER_DELIVERIES = "GET_REPORT_CUSTOMER_DELIVERIES";
export const GET_REPORT_DELIVERY_EXCEPTIONS = "GET_REPORT_DELIVERY_EXCEPTIONS";
export const GET_REPORT_GROSS_PROFITS = "GET_REPORT_GROSS_PROFITS";
export const GET_REPORT_DRIVER_STATISTICS = "GET_REPORT_DRIVER_STATISTICS";
export const GET_REPORT_DISTANCE_STATISTICS = "GET_REPORT_DISTANCE_STATISTICS";
export const GET_REPORT_PARCEL_STATISTICS = "GET_REPORT_PARCEL_STATISTICS";
export const GET_REPORT_SERVICE_LEVELS = "GET_REPORT_SERVICE_LEVELS";
export const GET_REPORT_DRIVER_PERFORMANCE = "GET_REPORT_DRIVER_PERFORMANCE";
export const GET_REPORT_DELIVERY_SUMMARY = "GET_REPORT_DELIVERY_SUMMARY";
export const GET_REPORT_DELIVERY_TERMINATION = "GET_REPORT_DELIVERY_TERMINATION";
export const GET_REPORT_DELIVERY_ARRIVAL = "GET_REPORT_DELIVERY_ARRIVAL";
export const GET_REPORT_DRIVER_AUTO_ASSIGN_EFFICIENCY = "GET_REPORT_DRIVER_AUTO_ASSIGN_EFFICIENCY";
export const GET_REPORT_DRIVER_ANALYSIS = "GET_REPORT_DRIVER_ANALYSIS";
export const GET_REPORT_DRIVER_SCHEDULE = "GET_REPORT_DRIVER_SCHEDULE";
export const GET_REPORT_HUB_DELIVERY_MOVEMENT = "GET_REPORT_HUB_DELIVERY_MOVEMENT";

export const CLEAR_REPORT_CUSTOMER_DELIVERIES = "CLEAR_REPORT_CUSTOMER_DELIVERIES";
export const CLEAR_REPORT_DELIVERY_EXCEPTIONS = "CLEAR_REPORT_DELIVERY_EXCEPTIONS";
export const CLEAR_REPORT_GROSS_PROFITS = "CLEAR_REPORT_GROSS_PROFITS";
export const CLEAR_REPORT_DRIVER_STATISTICS = "CLEAR_REPORT_DRIVER_STATISTICS";
export const CLEAR_REPORT_DISTANCE_STATISTICS = "CLEAR_REPORT_DISTANCE_STATISTICS";
export const CLEAR_REPORT_PARCEL_STATISTICS = "CLEAR_REPORT_PARCEL_STATISTICS";
export const CLEAR_REPORT_SERVICE_LEVELS = "CLEAR_REPORT_SERVICE_LEVELS";
export const CLEAR_REPORT_DRIVER_PERFORMANCE = "CLEAR_REPORT_DRIVER_PERFORMANCE";
export const CLEAR_REPORT_DELIVERY_SUMMARY = "CLEAR_REPORT_DELIVERY_SUMMARY";
export const CLEAR_REPORT_DELIVERY_TERMINATION = "CLEAR_REPORT_DELIVERY_TERMINATION";
export const CLEAR_REPORT_DELIVERY_ARRIVAL = "CLEAR_REPORT_DELIVERY_ARRIVAL";
export const CLEAR_REPORT_DRIVET_AUTO_ASSIGN_EFFICIENCY = "CLEAR_REPORT_DRIVET_AUTO_ASSIGN_EFFICIENCY";
export const CLEAR_REPORT_DRIVER_ANALYSIS = "CLEAR_REPORT_DRIVER_ANALYSIS";
export const CLEAR_REPORT_DRIVER_SCHEDULE = "CLEAR_REPORT_DRIVER_SCHEDULE";
export const CLEAR_REPORT_HUB_DELIVERY_MOVEMENT = "CLEAR_REPORT_HUB_DELIVERY_MOVEMENT";

/* archives */
export const GET_ARCHIVE_CUSTOMER_DELIVERIES = "GET_ARCHIVE_CUSTOMER_DELIVERIES";
export const GET_ARCHIVE_PARTNER_DELIVERIES = "GET_ARCHIVE_PARTNER_DELIVERIES";
export const GET_ARCHIVE_PARTNER_JOBS = "GET_ARCHIVE_PARTNER_JOBS";

export const CLEAR_ARCHIVE_CUSTOMER_DELIVERIES = "CLEAR_ARCHIVE_CUSTOMER_DELIVERIES";
export const CLEAR_ARCHIVE_PARTNER_DELIVERIES = "CLEAR_ARCHIVE_PARTNER_DELIVERIES";
export const CLEAR_ARCHIVE_PARTNER_JOBS = "CLEAR_ARCHIVE_PARTNER_JOBS";

/* vehicles */
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_SELECT = "GET_VEHICLES_SELECT";
export const POST_VEHICLES = "POST_VEHICLES";
export const PUT_VEHICLES = "PUT_VEHICLES";
export const DELETE_VEHICLES = "DELETE_VEHICLES";
export const CLEAR_VEHICLES = "CLEAR_VEHICLES";
export const CLEAR_VEHICLES_SUCCESS = "CLEAR_VEHICLES_SUCCESS";

/* hubs */
export const GET_HUBS = "GET_HUBS";
export const POST_HUBS = "POST_HUBS";
export const PUT_HUBS = "PUT_HUBS";
export const DELETE_HUBS = "DELETE_HUBS";
export const CLEAR_HUBS = "CLEAR_HUBS";
export const CLEAR_HUBS_SUCCESS = "CLEAR_HUBS_SUCCESS";


//this is specifically for getting a single area associated with a territory or a hub
export const POST_HUB_DELIVERIES = "POST_HUB_DELIVERIES";
export const PUT_HUB_DELIVERIES = "PUT_HUB_DELIVERIES";
export const GET_HUB_DELIVERIES = "GET_HUB_DELIVERIES";
export const DELETE_HUB_DELIVERIES = "DELETE_HUB_DELIVERIES";
export const CLEAR_HUB_DELIVERIES = "CLEAR_HUB_DELIVERIES";
export const CLEAR_HUB_DELIVERIES_SUCCESS = "CLEAR_HUB_DELIVERIES_SUCCESS";

/* rates */
export const GET_RATES = "GET_RATES";
export const POST_RATES = "POST_RATES";
export const PUT_RATES = "PUT_RATES";
export const DELETE_RATES = "DELETE_RATES";
export const CLEAR_RATES = "CLEAR_RATES";
export const CLEAR_RATES_SUCCESS = "CLEAR_RATES_SUCCESS";
export const SET_LOADING_RATES = "SET_LOADING_RATES";

/* stores */
export const GET_STORES = "GET_STORES";
export const GET_STORES_SELECT = "GET_STORES_SELECT";
export const SET_STORE_FOR_EDIT ="SET_STORE_FOR_EDIT"
export const POST_STORES = "POST_STORES";
export const PUT_STORES = "PUT_STORES";
export const DELETE_STORES = "DELETE_STORES";
export const CLEAR_STORES = "CLEAR_STORES";
export const CLEAR_STORES_SUCCESS = "CLEAR_STORES_SUCCESS";


/* store drivers */
export const GET_STORE_DRIVERS = "GET_STORE_DRIVERS";
export const CLEAR_STORE_DRIVERS = "CLEAR_STORE_DRIVERS";
export const DELETE_STORE_DRIVERS = "DELETE_STORE_DRIVERS";
export const POST_STORE_DRIVERS = "POST_STORE_DRIVERS";
export const CLEAR_STORE_DRIVERS_SUCCESS = "CLEAR_STORE_DRIVERS_SUCCESS";
export const SET_LOADING_STORE_DRIVERS = "SET_LOADING_STORE_DRIVERS";

/* accounts */
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNTS_SELECT = "GET_ACCOUNTS_SELECT";
export const POST_ACCOUNTS = "POST_ACCOUNTS";
export const PUT_ACCOUNTS = "PUT_ACCOUNTS";
export const UPDATE_LOCKED_AMOUNT = "UPDATE_LOCKED_AMOUNT";
export const DELETE_ACCOUNTS = "DELETE_ACCOUNTS";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";
export const CLEAR_ACCOUNTS_SUCCESS = "CLEAR_ACCOUNTS_SUCCESS";
export const CLEAR_ACCOUNTS_SELECT_SUCCESS = "CLEAR_ACCOUNTS_SELECT_SUCCESS";

/* contacts */
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_BY_ID = "GET_CONTACTS_BY_ID";
export const POST_CONTACTS = "POST_CONTACTS";
export const PUT_CONTACTS = "PUT_CONTACTS";
export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const CLEAR_CONTACTS_SUCCESS = "CLEAR_CONTACTS_SUCCESS";

/* notes */
export const GET_NOTES = "GET_NOTES";
export const GET_MORE_NOTES = "GET_MORE_NOTES";
export const POST_NOTES = "POST_NOTES";
export const PUT_NOTES = "PUT_NOTES";
export const DELETE_NOTES = "DELETE_NOTES";
export const CLEAR_NOTES = "CLEAR_NOTES";
export const CLEAR_NOTES_SUCCESS = "CLEAR_NOTES_SUCCESS";

/* application statuses */
export const GET_APPLICATION_STATUSES = "GET_APPLICATION_STATUSES";
export const SET_LOADING_APPLICATION_STATUSES = "SET_LOADING_APPLICATION_STATUSES";

/* application statuses */
export const GET_DOCUMENT_STATUSES = "GET_DOCUMENT_STATUSES";
export const SET_LOADING_DOCUMENT_STATUSES = "SET_LOADING_DOCUMENT_STATUSES";

/* training statuses */
export const GET_TRAINING_STATUSES = "GET_TRAINING_STATUSES";
export const SET_LOADING_TRAINING_STATUSES = "SET_LOADING_TRAINING_STATUSES";

/* contact types */
export const GET_CONTACT_TYPES = "GET_CONTACT_TYPES";

/* delivery windows */
export const GET_AVAILABLE_DATES = "GET_AVAILABLE_DATES";
export const GET_AVAILABLE_TIMESLOTS = "GET_AVAILABLE_TIMESLOTS";

/* addresses */
export const GET_ADDRESSES_SELECT = "GET_ADDRESSES_SELECT";
export const POST_ADDRESSES = "POST_ADDRESSES";
export const PUT_ADDRESSES = "PUT_ADDRESSES";
export const CLEAR_ADDRESSES_SUCCESS = "CLEAR_ADDRESSES_SUCCESS";

/* company types */
export const GET_COMPANY_TYPES = "GET_COMPANY_TYPES";
export const SET_LOADING_COMPANY_TYPES = "SET_LOADING_COMPANY_TYPES";

/* company pricing tiers */
export const GET_COMPANY_PRICING_TIERS = "GET_COMPANY_PRICING_TIERS";
export const SET_LOADING_COMPANY_PRICING_TIERS = "SET_LOADING_COMPANY_PRICING_TIERS";

/* delivery statuses */
export const GET_DELIVERY_STATUSES = "GET_DELIVERY_STATUSES";
export const GET_LOGISTIC_STATUSES = "GET_LOGISTIC_STATUSES";

/* job statuses */
export const GET_JOB_STATUSES = "GET_JOB_STATUSES";

/* task statuses */
export const GET_TASK_STATUSES = "GET_TASK_STATUSES";

/* task types */
export const GET_TASK_TYPES = "GET_TASK_TYPES";

/* payment statuses */
export const GET_PAYMENT_STATUSES = "GET_PAYMENT_STATUSES";

/* operation statuses */
export const GET_OPERATION_STATUSES = "GET_OPERATION_STATUSES";

/* vehicle types */
export const GET_VEHICLE_TYPES = "GET_VEHICLE_TYPES";
export const SET_LOADING_VEHICLE_TYPES = "SET_LOADING_VEHICLE_TYPES";

/* auto assign statuses */
export const GET_AUTO_ASSIGN_STATUSES = "GET_AUTO_ASSIGN_STATUSES";

/* roles */
export const GET_ROLES = "GET_ROLES";

/* account types */
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";

/* hub types */
export const GET_HUB_TYPES = "GET_HUB_TYPES";

/* rate types */
export const GET_RATE_TYPES = "GET_RATE_TYPES";

/* sla types */
export const GET_SLA_TYPES = "GET_SLA_TYPES";


export const GET_DRIVER_SPECIAL_FLAG_TYPES = "GET_DRIVER_SPECIAL_FLAG_TYPES";

/* account types */
export const GET_TIME_ZONES = "GET_TIME_ZONES";

/* day of week types */
export const GET_DAY_OF_WEEK_TYPES = "GET_DAY_OF_WEEK_TYPES";

/* parcel types */
export const GET_PARCEL_TYPES = "GET_PARCEL_TYPES";
export const GET_PARCEL_TYPES_SELECT = "GET_PARCEL_TYPES_SELECT";
export const SET_LOADING_PARCEL_TYPES = "SET_LOADING_PARCEL_TYPES";

/*Handline type*/
export const GET_HANDLING_TYPE = "GET_HANDLING_TYPE";

/* note message types */
export const GET_NOTE_MESSAGE_TYPES = "GET_NOTE_MESSAGE_TYPES";
export const CLEAR_NOTE_MESSAGE_TYPES_SUCCESS = "CLEAR_NOTE_MESSAGE_TYPES_SUCCESS";
export const GET_NOTE_MESSAGE_TYPE_CATEGORIES = "GET_NOTE_MESSAGE_TYPE_CATEGORIES";
export const CLEAR_NOTE_MESSAGE_TYPE_CATEGORIES_SUCCESS = "CLEAR_NOTE_MESSAGE_TYPECATEGORIES_SUCCESS";

/* transaction types/statuses */
export const GET_TRANSACTION_STATUSES = "GET_TRANSACTION_STATUSES";
export const GET_TRANSACTION_TYPES = "GET_TRANSACTION_TYPES";

/* fundsReceived types/statuses */
export const GET_FUNDS_RECEIVED_STATUSES = "GET_FUNDS_RECEIVED_STATUSES";
export const GET_FUNDS_RECEIVED_TYPES = "GET_FUNDS_RECEIVED_TYPES";

/* dashboard delivery status groups */
export const GET_DASHBOARD_DELIVERY_STATUS_GROUPS = "GET_DASHBOARD_DELIVERY_STATUS_GROUPS";

/* dashboard delivery sorts */
export const GET_DASHBOARD_DELIVERY_SORTS = "GET_DASHBOARD_DELIVERY_SORTS";

/* dashboard job status groups */
export const GET_DASHBOARD_JOB_STATUS_GROUPS = "GET_DASHBOARD_JOB_STATUS_GROUPS";

/* dashboard job sorts */
export const GET_DASHBOARD_JOB_SORTS = "GET_DASHBOARD_JOB_SORTS";

/* dashboard driver status groups */
export const GET_DASHBOARD_DRIVER_STATUS_GROUPS = "GET_DASHBOARD_DRIVER_STATUS_GROUPS";

/* dashboard driver sorts */
export const GET_DASHBOARD_DRIVER_SORTS = "GET_DASHBOARD_DRIVER_SORTS";

/* tracking */
export const GET_TRACKING = "GET_TRACKING";
export const SEARCH_TRACKING = "SEARCH_TRACKING";
export const CLEAR_TRACKING = "CLEAR_TRACKING";
export const CLEAR_TRACKING_SEARCH = "CLEAR_TRACKING_SEARCH";
export const CLEAR_TRACKING_DATA = "CLEAR_TRACKING_DATA";
export const CHANGE_TRACKING_COORDS = "CHANGE_TRACKING_COORDS";


/* driver types for keepings track of driver locations with relation to signal r */
export const UPDATE_DRIVER_LOCATIONS = "UPDATE_DRIVER_LOCATIONS";

/* SIGNAL R actions for connecting to groups and disconnecting from groups */
export const CONNECT_TO_SIGNALR = "CONNECT_TO_SIGNALR";
export const DISCONNECT_FROM_SIGNALR = "DISCONNECT_FROM_SIGNALR";

//coordinate updates
export const SUBSCRIBE_TO_COORDINATE_UPDATES = "SUBSCRIBE_TO_COORDINATE_UPDATES";
export const UNSUBSCRIBE_FROM_COORDINATE_UPDATES = "UNSUBSCRIBE_FROM_COORDINATE_UPDATES";
export const RESUBSCRIBE_TO_COORDINATE_UPDATES = "RESUBSCRIBE_TO_COORDINATE_UPDATES";

//subscribe to job task updates
export const SUBSCRIBE_TO_JOB_TASK_UPDATES = "SUBSCRIBE_TO_JOB_TASK_UPDATES";
export const UNSUBSCRIBE_FROM_JOB_TASK_UPDATES = "UNSUBSCRIBE_FROM_JOB_TASK_UPDATES";
export const RESUBSCRIBE_TO_JOB_TASK_UPDATES = "RESUBSCRIBE_TO_JOB_TASK_UPDATES";


//deliveries groups
export const SUBSCRIBE_TO_DELIVERIES_GROUP = "SUBSCRIBE_TO_DELIVERIES_GROUP";
export const UNSUBSCRIBE_FROM_DELIVERIES_GROUP = "UNSUBSCRIBE_FROM_DELIVERIES_GROUP";

//jobs groups
export const SUBSCRIBE_TO_JOBS_GROUP = "SUBSCRIBE_TO_JOBS_GROUP";
export const UNSUBSCRIBE_FROM_JOBS_GROUP = "UNSUBSCRIBE_FROM_JOBS_GROUP";

//users groups
export const SUBSCRIBE_TO_USERS_GROUP = "SUBSCRIBE_TO_USERS_GROUP";
export const UNSUBSCRIBE_FROM_USERS_GROUP = "UNSUBSCRIBE_FROM_USERS_GROUP"

//bulkundexer
export const SUBSCRIBE_TO_BULKINDEX_GROUP = "SUBSCRIBE_TO_BULKINDEX_GROUP";
export const UNSUBSCRIBE_FROM_BULKINDEX_GROUP = "UNSUBSCRIBE_FROM_BULKINDEX_GROUP"

export const UPDATE_BULK_INDEXER_TERMINAL = "UPDATE_BULK_INDEXER_TERMINAL";
export const CLEAR_BULK_INDEXER_TERMINAL = "CLEAR_BULK_INDEXER_TERMINAL";

//webNotifications
export const SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP = "SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP";
export const UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP = "UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP";

//update events for data coming in from signal R socket connection and group subscriptions
export const UPDATE_DELIVERIES = "UPDATE_DELIVERIES";
export const UPDATE_JOBS = "UPDATE_JOBS";
export const UPDATE_USERS = "UPDATE_USERS";
export const NEW_DELIVERIES = "NEW_DELIVERIES";
export const NEW_JOBS = "NEW_JOBS";
export const NEW_USERS = "NEW_USERS";


//loaders for delivery cards
export const DELIVERY_LOADING_ON = "DELIVERY_LOADING_ON";
export const DELIVERY_LOADING_OFF = "DELIVERY_LOADING_OFF";

//loaders for job cards
export const JOB_LOADING_ON = "JOB_LOADING_ON";
export const JOB_LOADING_OFF = "JOB_LOADING_OFF";

//loaders for driver cards
export const DRIVER_LOADING_ON = "DRIVER_LOADING_ON";
export const DRIVER_LOADING_OFF = "DRIVER_LOADING_OFF";

//delivery batches / uploads
export const GET_UPLOAD_BATCHES = "GET_UPLOAD_BATCHES";
export const POST_UPLOAD_BATCH = "POST_UPLOAD_BATCH";
export const DELETE_UPLOAD_BATCH = "DELETE_UPLOAD_BATCH";
export const SUBMIT_UPLOAD_BATCH = "SUBMIT_UPLOAD_BATCH";
export const CLEAR_UPLOAD_BATCHES = "CLEAR_UPLOAD_BATCHES";
export const CLEAR_UPLOAD_BATCHES_SUCCESS = "CLEAR_UPLOAD_BATCHES_SUCCESS";

//payment cards
export const GET_PAYMENT_CARDS = "GET_PAYMENT_CARDS";
export const DELETE_PAYMENT_CARD = "DELETE_PAYMENT_CARD";
export const CLEAR_PAYMENT_CARDS = "CLEAR_PAYMENT_CARDS";
export const CLEAR_PAYMENT_CARD_SUCCESS = "CLEAR_PAYMENT_CARD_SUCCESS";

//store batches / uploads
export const GET_STORE_UPLOADS = "GET_STORE_UPLOADS";
export const PUT_STORE_UPLOADS = "PUT_STORE_UPLOADS";
export const CANCEL_STORE_UPLOAD = "CANCEL_STORE_UPLOAD";
export const CLEAR_STORE_UPLOADS = "CLEAR_UPLOAD_BATCHES";
export const CLEAR_STORE_UPLOADS_SUCCESS = "CLEAR_UPLOAD_BATCHES_SUCCESS";

export const GET_DELIVERY_UPLOADS = "GET_DELIVERY_UPLOADS";
export const PUT_DELIVERY_UPLOADS = "PUT_DELIVERY_UPLOADS";
export const CANCEL_DELIVERY_UPLOAD = "CANCEL_DELIVERY_UPLOAD";
export const CLEAR_DELIVERY_UPLOADS = "CLEAR_UPLOAD_BATCHES";
export const CLEAR_DELIVERY_UPLOADS_SUCCESS = "CLEAR_UPLOAD_BATCHES_SUCCESS";

export const GET_LOGISTICAL_STATUS_UPLOADS = "GET_LOGISTICAL_STATUS_UPLOADS";
export const PUT_LOGISTICAL_STATUS_UPLOADS = "PUT_LOGISTICAL_STATUS_UPLOADS";
export const CANCEL_LOGISTICAL_STATUS_UPLOAD = "CANCEL_LOGISTICAL_STATUS_UPLOAD";
export const CLEAR_LOGISTICAL_STATUS_UPLOADS = "CLEAR_UPLOAD_BATCHES";
export const CLEAR_LOGISTICAL_STATUS_UPLOADS_SUCCESS = "CLEAR_UPLOAD_BATCHES_SUCCESS";
export const GET_RATE_UPLOADS = "GET_RATE_UPLOADS";
export const PUT_RATE_UPLOADS = "PUT_RATE_UPLOADS";
export const CANCEL_RATE_UPLOAD = "CANCEL_RATE_UPLOAD";
export const CLEAR_RATE_UPLOADS = "CLEAR_RATE_BATCHES";
export const CLEAR_RATE_UPLOADS_SUCCESS = "CLEAR_UPLOAD_BATCHES_SUCCESS";

export const GET_UPLOAD_STATUSES = "GET_UPLOAD_STATUSES";

//webhooks
export const GET_AUTH_TYPES = "GET_AUTH_TYPES";
export const GET_WEBHOOK_TYPES = "GET_WEBHOOK_TYPES";
export const GET_WEBHOOK_STATUSES = "GET_WEBHOOK_STATUSES";
export const GET_WEBHOOKS = "GET_WEBHOOKS";
export const PING_WEBHOOKS = "PING_WEBHOOKS";
export const POST_WEBHOOKS = "POST_WEBHOOKS";
export const PUT_WEBHOOKS = "PUT_WEBHOOKS";
export const DELETE_WEBHOOKS = "DELETE_WEBHOOKS";
export const CLEAR_WEBHOOKS = "CLEAR_WEBHOOKS";
export const CLEAR_WEBHOOKS_SUCCESS = "CLEAR_WEBHOOKS_SUCCESS";

//scheduled report
export const GET_SCHEDULED_REPORTS = "GET_SCHEDULED_REPORTS";
export const POST_SCHEDULED_REPORTS = "POST_SCHEDULED_REPORTS";
export const PUT_SCHEDULED_REPORTS = "PUT_SCHEDULED_REPORTS";
export const DELETE_SCHEDULED_REPORTS = "DELETE_SCHEDULED_REPORTS";
export const CLEAR_SCHEDULED_REPORTS = "CLEAR_SCHEDULED_REPORTS";
export const CLEAR_SCHEDULED_REPORTS_SUCCESS = "CLEAR_SCHEDULED_REPORTS_SUCCESS";
export const GET_SCHEDULED_REPORT_TYPES = "GET_SCHEDULED_REPORT_TYPES";
export const GET_SCHEDULED_REPORT_PERIODS = "GET_SCHEDULED_REPORT_PERIODS";
export const TEST_SCHEDULED_REPORT = "TEST_SCHEDULED_REPORT";

//statistics
export const GET_DELIVERY_STATS = "GET_DELIVERY_STATS";
export const GET_ACCEPTANCE_STATS = "GET_ACCEPTANCE_STATS";
export const GET_OTDS_STATS = "GET_OTDS_STATS";
export const GET_DROPOFF_COMPLIANCE_STATS = "GET_DROPOFF_COMPLIANCE_STATS";
export const GET_PICKUP_COMPLIANCE_STATS = "GET_PICKUP_COMPLIANCE_STATS";
export const GET_PICKUP_EXCEPTIONS_STATS = "GET_PICKUP_EXCEPTIONS_STATS";
export const GET_DROPOFF_EXCEPTIONS_STATS = "GET_DROPOFF_EXCEPTIONS_STATS";
export const CLEAR_STATS = "CLEAR_STATS";

//web
export const SET_NOTICATIONS = "SET_NOTICATIONS";
export const SET_NOTICATIONS_IS_LOADING = "SET_NOTICATIONS_IS_LOADING";
export const SET_NOTICATIONS_IS_FETCHING = "SET_NOTICATIONS_IS_FETCHING";
export const SET_NOTICATIONS_IS_COMPLETE = "SET_NOTICATIONS_IS_COMPLETE";
export const SET_NOTICATIONS_PAGE = "SET_NOTICATIONS_PAGE";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const REMOVE_ITEM_AT_INDEX = "REMOVE_ITEM_AT_INDEX";
export const UPDATE_ITEM_COUNT = "UPDATE_ITEM_COUNT";
export const PLAY_SOUND = "PLAY_SOUND";
export const IS_PLAYING = "IS_PLAYING";
export const MUTE = "MUTE";
export const WEB_NOTIFICATION_TYPES = "WEB_NOTIFICATION_TYPES";

//ratings
export const GET_RATINGS = "GET_RATINGS";
export const POST_RATINGS = "POST_RATINGS";
export const PUT_RATINGS = "PUT_RATINGS";
export const DELETE_RATINGS = "DELETE_RATINGS";
export const CLEAR_RATINGS = "CLEAR_RATINGS";
export const CLEAR_RATINGS_SUCCESS = "CLEAR_RATINGS_SUCCESS";


//ratings
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const POST_ANNOUNCEMENTS = "POST_ANNOUNCEMENTS";
export const PUT_ANNOUNCEMENTS = "PUT_ANNOUNCEMENTS";
export const CLEAR_ANNOUNCEMENTS = "CLEAR_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENTS = "DELETE_ANNOUNCEMENTS";
export const CLEAR_ANNOUNCEMENTS_SUCCESS = "CLEAR_ANNOUNCEMENTS_SUCCESS";


//recipient devices
export const GET_NOTIFICATION_DEVICES = "GET_NOTIFICATION_DEVICES";
export const POST_NOTIFICATION_DEVICES = "POST_NOTIFICATION_DEVICES";
export const PUT_NOTIFICATION_DEVICES = "PUT_NOTIFICATION_DEVICES";
export const CLEAR_NOTIFICATION_DEVICES = "CLEAR_NOTIFICATION_DEVICES";
export const DELETE_NOTIFICATION_DEVICES = "DELETE_NOTIFICATION_DEVICES";
export const CLEAR_NOTIFICATION_DEVICES_SUCCESS = "CLEAR_NOTIFICATION_DEVICES_SUCCESS";

//mobile devices
export const GET_MOBILE_DEVICES = "GET_MOBILE_DEVICES";
export const POST_MOBILE_DEVICES = "POST_MOBILE_DEVICES";
export const PUT_MOBILE_DEVICES = "PUT_MOBILE_DEVICES";
export const NOTIFY_MOBILE_DEVICE = "NOTIFY_MOBILE_DEVICE";
export const CLEAR_MOBILE_DEVICES = "CLEAR_MOBILE_DEVICES";
export const DELETE_MOBILE_DEVICES = "DELETE_MOBILE_DEVICES";
export const CLEAR_MOBILE_DEVICES_SUCCESS = "CLEAR_MOBILE_DEVICES_SUCCESS";

//questionnaire
export const GET_QUESTIONNAIRES = "GET_QUESTIONNAIRES";
export const POST_QUESTIONNAIRES = "POST_QUESTIONNAIRES";
export const PUT_QUESTIONNAIRES = "PUT_QUESTIONNAIRES";
export const CLEAR_QUESTIONNAIRES = "CLEAR_QUESTIONNAIRES";
export const DELETE_QUESTIONNAIRES = "DELETE_QUESTIONNAIRES";
export const CLEAR_QUESTIONNAIRES_SUCCESS = "CLEAR_QUESTIONNAIRES_SUCCESS";

export const POST_QUESTIONNAIRE_ANSWERS = "POST_QUESTIONNAIRE_ANSWERS";


//email
export const POST_SEND_EMAILS = "POST_SEND_EMAILS";
export const CLEAR_EMAIL = "CLEAR_EMAIL";
export const SET_LOADING_SEND_EMAILS = "SET_LOADING_SEND_EMAILS";
export const SET_EMAIL_DIALOG_OPEN = "SET_EMAIL_DIALOG_OPEN";

//config
export const GET_WEB_CONFIG = "GET_WEB_CONFIG";
export const GET_MOBILE_CONFIG = "GET_MOBILE_CONFIG";
export const SET_LOADING_CONFIG = 'SET_LOADING_CONFIG';

/* communication types */
export const GET_COMMUNICATION_TYPES = "GET_COMMUNICATION_TYPES";
export const CLEAR_COMMUNICATION_TYPES_SUCCESS = "CLEAR_COMMUNICATION_TYPES_SUCCESS";

/* qsr driver types */
export const GET_QSR_DRIVER_TYPES = "GET_QSR_DRIVER_TYPES";
export const SET_LOADING_QSR_DRIVER_TYPES = "SET_LOADING_QSR_DRIVER_TYPES";

/* user schedule */
export const POST_DRIVER_SCHEDULE = "POST_DRIVER_SCHEDULE";
export const PUT_DRIVER_SCHEDULE = "PUT_DRIVER_SCHEDULE";
export const GET_DRIVER_SCHEDULE_BY_ID = "GET_DRIVER_SCHEDULE_BY_ID";
export const GET_DRIVER_SCHEDULE_LATEST_ACTIVE = "GET_DRIVER_SCHEDULE_BY_ID";
export const GET_DRIVER_SCHEDULES = "GET_DRIVER_SCHEDULES";
export const CLEAR_DRIVER_SCHEDULE_SUCCESS = "CLEAR_DRIVER_SCHEDULE_SUCCESS";


// driver routes
export const GET_DRIVER_ROUTES = "GET_DRIVER_ROUTES";
/* superset  */
export const GET_SUPERSET_DASHBOARD_LIST="GET_SUPERSET_DASHBOARD_LIST";
export const GET_SUPERSET_GEUST_TOKEN="GET_SUPERSET_GEUST_TOKEN";
export const GET_SUPERSET_EMBEDDED_ID="GET_SUPERSET_EMBEDDED_ID";