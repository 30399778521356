import {
    CALL_TO_ACTION_GREEN_COLOR, COMPLEMENTARY_5_COLOR,
    DARK_GREEN_COLOR,
    ERROR_COLOR, LIGHT_BLUE,
    MEDIUM_FONT, PRIMARY_COLOR, SECONDARY_COLOR, SMALL_FONT, TERTIARY_COLOR,
    WHITE_COLOR
} from '../../../wumdrophubsreactshared/_constants/styleConstants';
import { lighten } from "@material-ui/core";

export default theme => ({
    field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        maxWidth: '78%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxWidth: '95%',
        }
    },
    isActiveField: {
        //marginLeft: theme.spacing(1),
    },
    isExclusionField: {
        //marginLeft: theme.spacing(1),
    },
    isHighRiskField: {
        //marginLeft: theme.spacing(1),
    },
    activeSpacing: {
        marginBottom: 5,
    },
    selectField: {
        width: '100%',
        maxWidth: '78%',
        marginBottom: theme.spacing(1),
        marginTop: 0,
        marginLeft: 5,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '95%',
        }
    },
    subField: {
        marginBottom: 22,
    },
    errorMessage: {
        color: ERROR_COLOR,
        marginBottom: theme.spacing(1) / 2
    },
    errorContainer: {
        margin: theme.spacing(1),
        paddingBottom: theme.spacing(1) * 2
    },
    requiredText: {
        color: ERROR_COLOR,
        fontSize: 12,
        marginTop: 5
    },
    successMessage: {
        color: DARK_GREEN_COLOR,
        margin: theme.spacing(1),
        paddingBottom: theme.spacing(1) * 2
    },
    //New
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: theme.spacing(1) * 2
    },
    feedback: {
        color: "red",
        fontSize: "12px"
    },
    group: {
        flexDirection: "row",
        marginLeft: 3,
        marginBottom: 5
    },
    button: {
        '&:focus': {
            outline: 'none'
        },
        //marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    actionsBar: {
        margin: '19px 7px',
    },
    actionsBarCompany: {
        margin: '0px 7px 40px 0px',
    },
    submitButton: {
        textTransform: 'capitalize',
        fontSize: MEDIUM_FONT,
        color: WHITE_COLOR,
        backgroundColor: CALL_TO_ACTION_GREEN_COLOR,
        paddingRight: 25,
        paddingLeft: 25,
        '&:hover': {
            background: lighten(CALL_TO_ACTION_GREEN_COLOR, 0.2) + "!important"
        },
    },
    cancelButton: {
        textTransform: 'capitalize',
        fontSize: MEDIUM_FONT,
        color: WHITE_COLOR,
        marginRight: 10,
        marginLeft: 4,
        '&:hover': {
            background: lighten(PRIMARY_COLOR, 0.2) + "!important"
        },
    },
    greenStatus: {
        background: lighten(CALL_TO_ACTION_GREEN_COLOR, 0.8),
    },
    blueStatus: {
        background: lighten(COMPLEMENTARY_5_COLOR, 0.8),
    },
    lightblueStatus: {
        background: lighten(LIGHT_BLUE, 0.6),
    },
    orangeStatus: {
        background: lighten(TERTIARY_COLOR, 0.9),
    },
    redStatus: {
        background: lighten(SECONDARY_COLOR, 0.9),
    },
    rightButton: {
        marginTop: -12,
        marginRight: -12,
        float: "right"
    },
    outlinedButton: {
        textTransform: "capitalize",
        fontSize: MEDIUM_FONT,
        border: '1px solid black',
        padding: '5px 15px',
        float: "right",
        marginBottom: 15
    },
    dropzoneParagraph: {
        fontSize: SMALL_FONT,
    },
    invisible: {
        display: 'none'
    },
    ////////////
    paper: {
        position: 'relative', // Ensure the Grid item is positioned relative for the overlay to work
        //zIndex: 0, // Set the z-index to a lower value
        marginBottom: 20,
        padding: 20
    },
    saveFab: {
        color: WHITE_COLOR,
        backgroundColor: DARK_GREEN_COLOR,
        float: "right"
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the alpha value for transparency
        zIndex: 1, // Make sure the overlay is above the Grid item
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }
    }

});