import { GET_TERRITORIES, 
         POST_TERRITORIES, 
         PUT_TERRITORIES, 
         DELETE_TERRITORIES,
         CLEAR_TERRITORIES,
         CLEAR_TERRITORIES_SUCCESS,
         GET_ERRORS,
         GET_TERRITORY_PERMISSIONS} from './types';
import axios from 'axios';
import { API_BASE_URL, API_TERRITORIES } from '../_constants/apiConstants';
import serializeQuery from '../_utils/serializeQuery';
import buildQueryMeta from '../_utils/buildQueryMeta';
import buildResponseMeta from '../_utils/buildResponseMeta';
import isEmpty from '../_utils/isEmpty';
import buildQueryDelete from '../_utils/buildQueryDelete';
import store from '../_utils/store';

const apiBaseUrl = API_BASE_URL;
const apiTerritoriesEndpoint = API_TERRITORIES;

export const getPermissions = () => dispatch => {
    return axios.get(`${apiBaseUrl}${apiTerritoriesEndpoint}/TerritoryPermissions`)
        .then(res => {
            dispatch({
                type: GET_TERRITORY_PERMISSIONS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}


export const getTerritories = (territoryId="") => dispatch => {
    if(!isEmpty(territoryId)) {
        territoryId = "/"+territoryId;
    }
    let portalCountryCode = store.getState().portal.data.cca2;

    return axios.get(`${apiBaseUrl}${apiTerritoriesEndpoint}/${portalCountryCode}${territoryId}`)
        .then(res => {
            dispatch({
                type: GET_TERRITORIES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}

export const getTerritoriesPage = (meta) => dispatch => {
    return _getPage(meta, dispatch);
}

export const createTerritory = (territory, history) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return axios.post(apiBaseUrl + apiTerritoriesEndpoint + "/" + portalCountryCode, territory)
        .then(res => {
            dispatch({
                type: POST_TERRITORIES,
                payload: res.data
            });
            history.push('/territories/'+res.data.id+'/areas');
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
        });
    });
}

export const updateTerritory = (territoryId, territory) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return axios.put(apiBaseUrl + apiTerritoriesEndpoint + "/" + portalCountryCode + "/" + territoryId , territory)
        .then(res => {
            dispatch({
                type: PUT_TERRITORIES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
        });
    });
}

export const deleteTerritory = (territoryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return axios.delete(apiBaseUrl + apiTerritoriesEndpoint + "/" + portalCountryCode + "/" + territoryId)
        .then(res => {
            dispatch({
                type: DELETE_TERRITORIES,
                payload: [territoryId]
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
        });
    });
}

export const deleteTerritoriesBulk = (territoryIds, meta) => dispatch => {
    let query = buildQueryDelete(territoryIds);
    let portalCountryCode = store.getState().portal.data.cca2;
    return axios.delete(apiBaseUrl + apiTerritoriesEndpoint + "/" + portalCountryCode + "?" + serializeQuery(query))
        .then(res => {
            dispatch({
                type: DELETE_TERRITORIES,
                payload: territoryIds
            })
        })
        .then(res => {
            _getPage(meta, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
        });
    });
}

export const clearTerritoriesSuccess = () => dispatch => {
    dispatch({
           type: CLEAR_TERRITORIES_SUCCESS,
           payload: ''
   });
}

export const clearTerritories = () => dispatch => {
    dispatch({
           type: CLEAR_TERRITORIES,
           payload: ''
   });
}

/** Helper functions */
//internal get territories page function - used in getTerritoriesPage() and deleteTerritoriesBulk()

let _getPage = (meta, dispatch) => {
    let query = buildQueryMeta(meta);
    let portalCountryCode = store.getState().portal.data.cca2;
    return axios.get(apiBaseUrl + apiTerritoriesEndpoint + "/" + portalCountryCode + "?" + serializeQuery(query))
            .then(res => {
                res = buildResponseMeta(res, meta);
                dispatch({
                    type: GET_TERRITORIES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
            });
    });
}


