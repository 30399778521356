import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import axios from 'axios';
import { API_BASE_URL } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import { FONT_FAMILY, SMALL_FONT } from '../../../wumdrophubsreactshared/_constants/styleConstants';
import Icon from "@material-ui/core/Icon";
import { makeStyles } from '@material-ui/core';

const customSelectStyles = {
    selectContainer: {
        margin: "0 0 15px 0",
        padding: 0
    },
    inputIcon: {
        height: "33px",
        position: "absolute",
        zIndex: "1",
        marginTop: 6
    },
    control: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        borderRadius: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: '1px solid hsl(0,0%,80%) !important',
        boxShadow: '0 !important'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
    }),
    placeholder: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT,
        color: 'rgba(0, 0, 0, 0.40)',
        marginLeft: 23,
    }),
    input: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT,
        alignItems: 'center',
        display: 'flex',
        ':before': {
            content: '" "',
            marginLeft: -3,
            height: 25,
            width: 25
        },
    }),
    menu: base => ({
        ...base,
        fontFamily: FONT_FAMILY,
        zIndex: "2"
    }),
    option: (base) => ({
        ...base,
        paddingLeft: 25,
        fontFamily: FONT_FAMILY,
        textAlign: 'left',
        justContent: 'left'
    }),
    noOptionsMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY,
        color: 'rgba(0, 0, 0, 0.40)'
    }),
    loadingMessage: (base) => ({
        ...base,
        fontFamily: FONT_FAMILY
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft: 23,
        fontFamily: FONT_FAMILY,
        fontSize: SMALL_FONT
    })
};

const useStyles = makeStyles(() => ({
    selectContainer: {
        margin: "0 0 15px 0",
        padding: 0
    },
    inputIcon: {
        height: "33px",
        position: "absolute",
        zIndex: "1",
        marginTop: 6
    }
}));

const DatePicker = ({ apiEntity, placeholder, value, onSelect, fieldName }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState("");
    const [options, setOptions] = useState([]);
    const [noOptions, setNoOptions] = useState('Loading...');

    const handleChange = (selected) => {
        setSelected(selected);
        onSelect({ value: selected.value, field: fieldName });
    };

    useEffect(() => {
        if (!isEmpty(value)) {
            setSelected(value);
        }
    }, [value]);

    useEffect(() => {
        (() => {
            axios.get(`${API_BASE_URL}/${apiEntity}`)
                .then((res) => {
                    if (isEmpty(res.data)) {
                        setNoOptions("No dates available");
                        setOptions([]);
                    } else {
                        let newOptions = []
                        res.data.forEach(element => {
                            newOptions.push({ value: element.key, label: element.value })
                        });
                        setOptions(newOptions);
                    }
                })
                .catch(() => {
                    setNoOptions("Oops! something went wrong");
                    setOptions([]);
                });
        })();
    }, [apiEntity]);

    return (
        <div className={classes.selectContainer}>
            <Icon className={classes.inputIcon} alt="Address Icon">date_range</Icon>
            <Select
                styles={customSelectStyles}
                options={options}
                noOptionsMessage={() => noOptions}
                placeholder={placeholder}
                value={selected}
                onChange={handleChange}
            />
            <section className={classes.underline} />
        </div>
    );
};

DatePicker.propTypes = {
    apiEntity: PropTypes.string.isRequired
};

export default DatePicker;
